import { LOGIN_API } from "./apiConfig";

const getUserByEmail = async (email: string, password: string) => {
  const response = await fetch(LOGIN_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });

  if (!response.ok) {
    throw new Error("Failed to authenticate user.");
  }

  const apiRes = await response.json();
  return apiRes.data;
};

export default getUserByEmail;
