import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  CrudFilters,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  BooleanField,
  useSelect,
  TextField,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Row,
  Col,
  Card,
  Form,
  Input,
  FormProps,
  Select,
  Avatar,
} from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import { IRegion, IRole, IStore, ITeam } from "interfaces";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q, email, role, team, region, store } = params;

      filters.push({
        field: "user_region.id",
        operator: "eq",
        value: region,
      });

      filters.push({
        field: "user_store.id",
        operator: "eq",
        value: store,
      });

      filters.push({
        field: "user_team.id",
        operator: "eq",
        value: team,
      });

      filters.push({
        field: "user_role.id",
        operator: "eq",
        value: role,
      });

      filters.push({
        field: "email",
        operator: "contains",
        value: email,
      });

      filters.push({
        operator: "or",
        value: [
          {
            field: "name",
            operator: "contains",
            value: q,
          },
          {
            field: "employeeId",
            operator: "contains",
            value: q,
          },
        ],
      });

      return filters;
    },
    syncWithLocation: false,
  });

  return (
    <Row gutter={[16, 16]}>
      <Col xl={24} lg={24} xs={24}>
        <Card title='Filter'>
          <Filter formProps={searchFormProps} />
        </Card>
      </Col>
      <Col xl={24} xs={24}>
        <List>
          <Table {...tableProps} rowKey='id'>
            <Table.Column dataIndex='id' title='Id' />
            <Table.Column dataIndex='employeeId' title='Employee Id' />
            <Table.Column
              dataIndex={["email", "name"]}
              title='User'
              render={(value: any, record: any) => {
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: "12px" }}>
                      <Avatar size='large' icon={<UserOutlined />} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontWeight: 600 }}>{record.name}</span>
                      <TextField value={record.email} />
                    </div>
                  </div>
                );
              }}
            />
            <Table.Column
              dataIndex={["team", "store", "region", "name"]}
              title='Region'
            />
            <Table.Column dataIndex={["team", "store", "name"]} title='Store' />
            <Table.Column dataIndex={["team", "name"]} title='Team' />
            <Table.Column dataIndex={["role", "name"]} title='Role' />
            {/* <Table.Column dataIndex={["team", "store", "name"]} title='Store' /> */}
            <Table.Column
              dataIndex={["isActive"]}
              title='Is Active'
              render={(value: any) => <BooleanField value={value} />}
            />
            <Table.Column
              title='Actions'
              dataIndex='actions'
              render={(_, record: BaseRecord) => (
                <Space>
                  <EditButton hideText size='small' recordItemId={record.id} />
                  <ShowButton hideText size='small' recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();
  const { selectProps: roleSelectProps } = useSelect<IRole>({
    resource: "roles",
    optionLabel: "name",
    optionValue: "id",
  });
  const { selectProps: regionSelectProps } = useSelect<IRegion>({
    resource: "regions",
    optionLabel: "name",
    optionValue: "id",
  });
  const { selectProps: teamSelectProps } = useSelect<ITeam>({
    resource: "teams",
    optionLabel: "name",
    optionValue: "id",
  });
  const { selectProps: storeSelectProps } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Form
      layout='vertical'
      {...props.formProps}
      onValuesChange={() => {
        props.formProps.form?.submit();
      }}
    >
      <Row gutter={[10, 0]} align='bottom'>
        <Col xl={6} md={6} xs={12}>
          <Form.Item label={t("Region")} name={["region"]}>
            <Select
              {...regionSelectProps}
              allowClear
              placeholder={t("Select Region")}
            />
          </Form.Item>
        </Col>
        <Col xl={6} md={6} xs={12}>
          <Form.Item label={t("Store")} name={["store"]}>
            <Select
              {...storeSelectProps}
              allowClear
              placeholder={t("Select Store")}
            />
          </Form.Item>
        </Col>
        <Col xl={6} md={6} xs={12}>
          <Form.Item label={t("Team")} name='team'>
            <Select
              {...teamSelectProps}
              allowClear
              placeholder={t("Select Team")}
            />
          </Form.Item>
        </Col>
        <Col xl={6} md={6} xs={12}>
          <Form.Item label={t("Role")} name='role'>
            <Select
              {...roleSelectProps}
              allowClear
              placeholder={t("Select Role")}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={12}>
          <Form.Item name='q' noStyle>
            <Input
              style={{
                width: "400px",
              }}
              placeholder={t("Search user or employee ID")}
              suffix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
