import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  notificationProvider,
  ThemedLayoutV2,
  ThemedTitleV2,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import nestjsxCrudDataProvider from "./utils/nestjsxCrudDataProvider";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { ForgotPassword } from "pages/forgotPassword";
import { Register } from "pages/register";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./utils/authProvider";
import { Header } from "./components/header";
import { ColorModeContextProvider } from "./contexts/color-mode";
import oktaAuth from "utils/oktaAuth";
import { Login } from "pages/login";
import { LoginByEmail } from "pages/login/login-by-email";
import DFSLogo from "components/logo";
import { API_URL } from "utils/apiConfig";
import { UserList } from "pages/users/list";
import { UserCreate } from "pages/users/create";
import { UserEdit } from "pages/users/edit";
import { UserShow } from "pages/users/show";
import axiosInstance from "utils/httpClient";
import {
  ShopOutlined,
  UserOutlined,
  SettingOutlined,
  GlobalOutlined,
  CompassOutlined,
  TeamOutlined,
  LockOutlined,
  CheckCircleOutlined,
  AppstoreOutlined,
  BarChartOutlined,
  PieChartOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useIdleTimer } from "react-idle-timer";
import { PollCreate, PollEdit, PollList, PollShow } from "pages/polls";
import { DashboardPage } from "pages/dashboard";
import {
  AdminUserCreate,
  AdminUserEdit,
  AdminUserList,
  AdminUserShow,
} from "pages/admin-users";
import {
  RegionCreate,
  RegionEdit,
  RegionList,
  RegionShow,
} from "pages/regions";
import { RegionalStoreAccess } from "pages/regional-store-access";
import { StoreCreate, StoreEdit, StoreList, StoreShow } from "pages/stores";
import { TeamCreate, TeamEdit, TeamList, TeamShow } from "pages/teams";
import { RoleCreate, RoleEdit, RoleList, RoleShow } from "pages/roles";
import {
  ServiceCreate,
  ServiceEdit,
  ServiceList,
  ServiceShow,
} from "pages/services";
import {
  PermissionCreate,
  PermissionEdit,
  PermissionList,
  PermissionShow,
} from "pages/permissions";
import {
  LanguageCreate,
  LanguageEdit,
  LanguageList,
  LanguageShow,
} from "pages/languages";
import { accessControlProvider } from "utils/accessControlProvider";
import { AnalysisPreference } from "pages/analysis-preference";
import { DefaultLanguage } from "pages/default-language";
import { CustomSider } from "components/sider";

function App() {
  const { t, i18n } = useTranslation();

  const handleOnIdle = async (event: any) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    // 执行登出操作
    await authProvider.logout({});
  };

  const handleOnAction = (event: any) => {
    // console.log("user did something", event);
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 40 * 60, // 40分钟未操作就自动登出
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
  });

  // const API_URL = "https://api.nestjsx-crud.refine.dev";
  const dataProvider = nestjsxCrudDataProvider(API_URL ?? "", axiosInstance);

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  localStorage.setItem("colorMode", "light");

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            dataProvider={dataProvider}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            accessControlProvider={accessControlProvider}
            resources={[
              {
                name: "dashboards",
                list: "/",
                meta: { icon: <PieChartOutlined /> },
              },
              {
                name: "polls",
                list: "/polls",
                create: "/polls/create",
                edit: "/polls/edit/:id",
                show: "/polls/show/:id",
                meta: { canDelete: true, icon: <BarChartOutlined /> },
              },
              {
                name: "settings",
                meta: { icon: <SettingOutlined /> },
              },
              {
                name: "admin users",
                list: "/admin-users",
                create: "/admin-users/create",
                edit: "/admin-users/edit/:id",
                show: "/admin-users/show/:id",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <UserAddOutlined />,
                },
              },
              {
                name: "region settings",
                list: "/regions",
                create: "/regions/create",
                edit: "/regions/edit/:id",
                show: "/regions/show/:id",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <CompassOutlined />,
                },
              },
              {
                name: "regional store accesses",
                list: "/regional-store-access",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <CompassOutlined />,
                },
              },
              {
                name: "default languages",
                list: "/default-language",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <ShopOutlined />,
                },
              },
              {
                name: "analysis preferences",
                list: "/analysis-preference",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <ShopOutlined />,
                },
              },
              {
                name: "users",
                list: "/users",
                create: "/users/create",
                edit: "/users/edit/:id",
                show: "/users/show/:id",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <UserOutlined />,
                },
              },
              {
                name: "stores",
                list: "/stores",
                create: "/stores/create",
                edit: "/stores/edit/:id",
                show: "/stores/show/:id",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <ShopOutlined />,
                },
              },
              {
                name: "teams",
                list: "/teams",
                create: "/teams/create",
                edit: "/teams/edit/:id",
                show: "/teams/show/:id",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <TeamOutlined />,
                },
              },
              {
                name: "languages",
                list: "/languages",
                create: "/languages/create",
                edit: "/languages/edit/:id",
                show: "/languages/show/:id",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <GlobalOutlined />,
                },
              },
              {
                name: "roles",
                list: "/roles",
                create: "/roles/create",
                edit: "/roles/edit/:id",
                show: "/roles/show/:id",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <LockOutlined />,
                },
              },
              {
                name: "permissions",
                list: "/permissions",
                create: "/permissions/create",
                edit: "/permissions/edit/:id",
                show: "/permissions/show/:id",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <CheckCircleOutlined />,
                },
              },
              {
                name: "services",
                list: "/services",
                create: "/services/create",
                edit: "/services/edit/:id",
                show: "/services/show/:id",
                meta: {
                  parent: "Settings",
                  canDelete: true,
                  icon: <AppstoreOutlined />,
                },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              disableTelemetry: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to='/login' />}>
                    <ThemedLayoutV2
                      Header={Header}
                      Sider={CustomSider}
                      Title={({ collapsed }) => (
                        <ThemedTitleV2
                          // collapsed is a boolean value that indicates whether the <Sidebar> is collapsed or not
                          collapsed={collapsed}
                          icon={<DFSLogo />}
                          text='DFS MySA CMS'
                        />
                      )}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<DashboardPage />} />
                <Route path='/polls'>
                  <Route index element={<PollList />} />
                  <Route path='create' element={<PollCreate />} />
                  <Route path='edit/:id' element={<PollEdit />} />
                  <Route path='show/:id' element={<PollShow />} />
                </Route>
                <Route path='/admin-users'>
                  <Route index element={<AdminUserList />} />
                  <Route path='create' element={<AdminUserCreate />} />
                  <Route path='edit/:id' element={<AdminUserEdit />} />
                  <Route path='show/:id' element={<AdminUserShow />} />
                </Route>
                <Route path='/users'>
                  <Route index element={<UserList />} />
                  <Route path='create' element={<UserCreate />} />
                  <Route path='edit/:id' element={<UserEdit />} />
                  <Route path='show/:id' element={<UserShow />} />
                </Route>
                <Route path='/roles'>
                  <Route index element={<RoleList />} />
                  <Route path='create' element={<RoleCreate />} />
                  <Route path='edit/:id' element={<RoleEdit />} />
                  <Route path='show/:id' element={<RoleShow />} />
                </Route>
                <Route path='/permissions'>
                  <Route index element={<PermissionList />} />
                  <Route path='create' element={<PermissionCreate />} />
                  <Route path='edit/:id' element={<PermissionEdit />} />
                  <Route path='show/:id' element={<PermissionShow />} />
                </Route>
                <Route path='/regions'>
                  <Route index element={<RegionList />} />
                  <Route path='create' element={<RegionCreate />} />
                  <Route path='edit/:id' element={<RegionEdit />} />
                  <Route path='show/:id' element={<RegionShow />} />
                </Route>
                <Route path='/regional-store-access'>
                  <Route index element={<RegionalStoreAccess />} />
                </Route>
                <Route path='/languages'>
                  <Route index element={<LanguageList />} />
                  <Route path='create' element={<LanguageCreate />} />
                  <Route path='edit/:id' element={<LanguageEdit />} />
                  <Route path='show/:id' element={<LanguageShow />} />
                </Route>
                <Route path='/stores'>
                  <Route index element={<StoreList />} />
                  <Route path='create' element={<StoreCreate />} />
                  <Route path='edit/:id' element={<StoreEdit />} />
                  <Route path='show/:id' element={<StoreShow />} />
                </Route>
                <Route path='/analysis-preference'>
                  <Route index element={<AnalysisPreference />} />
                </Route>
                <Route path='/default-language'>
                  <Route index element={<DefaultLanguage />} />
                </Route>
                <Route path='/teams'>
                  <Route index element={<TeamList />} />
                  <Route path='create' element={<TeamCreate />} />
                  <Route path='edit/:id' element={<TeamEdit />} />
                  <Route path='show/:id' element={<TeamShow />} />
                </Route>
                <Route path='/services'>
                  <Route index element={<ServiceList />} />
                  <Route path='create' element={<ServiceCreate />} />
                  <Route path='edit/:id' element={<ServiceEdit />} />
                  <Route path='show/:id' element={<ServiceShow />} />
                </Route>
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path='/login' element={<Login oktaAuth={oktaAuth} />} />
                <Route path='/login-by-email' element={<LoginByEmail />} />
                <Route path='/register' element={<Register />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
              </Route>
              <Route
                element={
                  <Authenticated>
                    <ThemedLayoutV2 Header={Header} Sider={CustomSider}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path='*' element={<ErrorComponent />} />
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
