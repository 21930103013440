import React, { useState } from "react";
import { Popover } from "antd";
import { Badges } from "enums";
import { PlusOutlined } from "@ant-design/icons";

import "./index.css";

interface Props {
  value: string;
  onChange(newValue: string): void;
}

const Badge = ({ value, onChange }: Props) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      open={open}
      onOpenChange={handleOpenChange}
      trigger={"click"}
      content={
        <div
          style={{
            padding: "20px",
            display: "grid",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
            gridGap: "10px",
          }}
        >
          {Object.values(Badges).map((badge, index) => {
            return (
              <div
                key={index}
                className={`badge-select-item ${
                  badge === value ? "badge-select-item__active" : ""
                }`}
                onClick={() => {
                  onChange(badge);
                  hide();
                }}
              >
                <img
                  src={`/images/badges/badge-${badge}.png`}
                  width={60}
                  alt={badge}
                />
              </div>
            );
          })}
        </div>
      }
    >
      <div style={{ cursor: "pointer" }}>
        {value ? (
          <img
            src={`/images/badges/badge-${value}.png`}
            width={40}
            alt={value}
          />
        ) : (
          <div className='badge-empty'>
            <PlusOutlined color='#999796' />
          </div>
        )}
      </div>
    </Popover>
  );
};

export default Badge;
