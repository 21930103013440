import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useTable } from "@refinedev/antd";
import { Checkbox, Form, Input, Table } from "antd";
import { IPermission, IService } from "interfaces";

export const RoleCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, onFinish } = useForm();
  const { tableProps } = useTable<IService>({
    resource: "services"
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical"
        onFinish={(values: any) => {
          let permissions = []
          for (const [key, value] of Object.entries(values)) {
            const acitons = value as [];
            if (acitons && key.startsWith('permissions.')) {
              permissions.push(acitons.map((t: string) => { return { id: t } }))
            }
          }
          permissions = permissions.flat()

          const result = {
            name: values.name,
            permissions
          }

          onFinish(result).catch((error) => error)
        }}
      >
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Table {...tableProps} rowKey="id"
          pagination={{
            ...tableProps.pagination,
            pageSize: 1000,
            hideOnSinglePage: true
          }}>
          <Table.Column dataIndex="name" title="Service" />
          <Table.Column
            dataIndex="permissions"
            title="Permissions"
            render={(value: [], data: IService) =>
              <Form.Item name={`permissions.${data.id}`} valuePropName="checked">
                <Checkbox.Group options={
                  value.slice()
                    .sort((a: IPermission, b: IPermission) => a.action.localeCompare(b.action))
                    .map((a: IPermission) => { return { label: a.action, value: a.id } })} />
              </Form.Item>
            }
          />
        </Table>
      </Form>
    </Create>
  );
};
