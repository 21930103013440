import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useTranslate,
  CrudFilters,
} from "@refinedev/core";
import {
  List,
  useSelect,
  useEditableTable,
  SaveButton,
  useForm,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Form,
  Col,
  Row,
  FormProps,
  Select,
  Button,
  Menu,
  Dropdown,
  Input,
  Radio,
  Card,
  Drawer,
  Switch,
} from "antd";
import { SearchOutlined, FormOutlined, MoreOutlined } from "@ant-design/icons";
import { IRegion, IStore } from "interfaces";

export const AnalysisPreference: React.FC<IResourceComponentsProps> = () => {
  const [open, setOpen] = useState(false);
  const t = useTranslate();
  const [commissionType, setCommissionType] = useState<"percentage" | "amount">(
    "percentage"
  );

  const {
    form,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    onFinish: createOnFinish,
  } = useForm({
    resource: "stores",
    redirect: false,
  });

  const {
    tableProps,
    formProps,
    searchFormProps,
    isEditing,
    onFinish,
    saveButtonProps,
    cancelButtonProps,
    setId,
    tableQueryResult,
  } = useEditableTable({
    resource: "stores",
    syncWithLocation: true,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { region, q, commissionType } = params;

      filters.push({
        field: "sr.id",
        operator: "eq",
        value: region,
      });

      filters.push({
        field: "commissionType",
        operator: "eq",
        value: commissionType,
      });

      filters.push({
        field: "name",
        operator: "contains",
        value: q,
      });

      return filters;
    },
  });

  const { selectProps: regionSelectProps } = useSelect<IRegion>({
    resource: "regions",
    optionLabel: "name",
    optionValue: "id",
  });

  const moreMenu = (record: IStore) => (
    <Menu
      mode='vertical'
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      <Menu.Item
        key='accept'
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <FormOutlined
            style={{
              color: "#52c41a",
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        onClick={() => {
          setId?.(record.id);
        }}
      >
        {t("buttons.edit")}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <List
        headerButtons={
          <Button
            type='primary'
            onClick={() => {
              setOpen(true);
            }}
          >
            + Add Store
          </Button>
        }
      >
        <Card style={{ marginBottom: "18px" }}>
          <Filter formProps={searchFormProps} />
        </Card>
        <Form
          {...formProps}
          onFinish={(values: any) => {
            const result = {
              region: { id: values.regionEdit },
              name: values.name,
              commissionType: values.commissionType
            };

            onFinish(result)
              .then(() => {
                setId(undefined);
              })
              .catch((error) => error);
          }}
        >
          <Table
            {...tableProps}
            rowKey='id'
            onRow={(record) => ({
              // eslint-disable-next-line
              onClick: (event: any) => {
                if (event.target.nodeName === "TD") {
                  setId && setId(record.id);
                }
              },
            })}
          >
            <Table.Column dataIndex='id' title='Id' />
            <Table.Column
              dataIndex={["region", "name"]}
              title='Region'
              render={(_: any, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <Form.Item
                      name='regionEdit'
                      style={{
                        marginBottom: "0px",
                      }}
                      initialValue={record?.region?.id}
                    >
                      <Select
                        {...regionSelectProps}
                        allowClear
                        placeholder={t("Select Region")}
                      />
                    </Form.Item>
                  );
                } else {
                  return <span>{record?.region?.name}</span>;
                }
              }}
            />
            <Table.Column
              dataIndex='name'
              title='Store location'
              render={(_: any, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <Form.Item
                      style={{
                        marginBottom: "0px",
                      }}
                      name='name'
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        size='middle'
                        placeholder={t("Input Store location")}
                      />
                    </Form.Item>
                  );
                } else {
                  return <span>{record?.name}</span>;
                }
              }}
            />
            <Table.Column
              title='Amount ($) or Percentage (%)'
              render={(_: any, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <div style={{ display: "flex" }}>
                      <Form.Item
                        name='commissionType'
                        style={{
                          marginBottom: "0px",
                          marginRight: "12px",
                        }}
                      >
                        <Radio.Group>
                          <Radio.Button value='amount'>$</Radio.Button>
                          <Radio.Button value='percentage'>%</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  );
                } else {
                  return record?.commissionType === 'amount' ? 'Actual amount' : 'Percentage'
                }
              }}
            />
            <Table.Column
              title='ACTIONS'
              dataIndex='actions'
              render={(_text, record: IStore) => {
                if (isEditing(record.id)) {
                  return (
                    <Space>
                      <SaveButton {...saveButtonProps} size='small' />
                      <Button {...cancelButtonProps} size='small'>
                        {t("buttons.cancel")}
                      </Button>
                    </Space>
                  );
                }
                return (
                  <Dropdown overlay={moreMenu(record)} trigger={["click"]}>
                    <MoreOutlined
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontSize: 24,
                      }}
                    />
                  </Dropdown>
                );
              }}
            />
          </Table>
        </Form>
      </List>
      <Drawer
        title='Add store'
        placement='right'
        size='default'
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        extra={
          <Space>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <SaveButton {...createSaveButtonProps} />
          </Space>
        }
      >
        <Form
          {...createFormProps}
          layout='vertical'
          onFinish={(values: any) => {
            const result = {
              ...values,
              commissionType: commissionType,
            };

            createOnFinish(result)
              .then(() => {
                form.resetFields();
                tableQueryResult.refetch();
                setOpen(false);
              })
              .catch((error) => error);
          }}
        >
          <Form.Item
            label='Region'
            name='region'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              {...regionSelectProps}
              allowClear
              placeholder='Select Region'
            />
          </Form.Item>
          <Form.Item
            label='Store location'
            name={["name"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size='middle' />
          </Form.Item>
          <Form.Item label='Commission Type' name='commission_type'>
            <Switch
              checked={commissionType !== "percentage"}
              onChange={(v) => {
                if (v) {
                  setCommissionType("amount");
                } else {
                  setCommissionType("percentage");
                }
              }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();

  const amountTypeOptions = [
    { value: "amount", label: "Amount" },
    { value: "percentage", label: "Percentage" },
  ];

  const { selectProps: regionSelectProps } = useSelect<IRegion>({
    resource: "regions",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Form
      layout='vertical'
      {...props.formProps}
      onValuesChange={() => {
        props.formProps.form?.submit();
      }}
    >
      <Row gutter={[10, 0]} align='bottom'>
        <Col xl={6} md={12} xs={12}>
          <Form.Item label={t("Region")} name={["region"]}>
            <Select
              {...regionSelectProps}
              allowClear
              placeholder={t("Select Region")}
            />
          </Form.Item>
        </Col>
        <Col xl={6} md={6} xs={12}>
          <Form.Item label={t("Amount or Percentage")} name='commissionType'>
            <Select
              options={amountTypeOptions}
              allowClear
              placeholder={t("Select Commission Type")}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={12}>
          <Form.Item name='q' noStyle>
            <Input
              style={{
                width: "380px",
              }}
              placeholder={t("Search store location")}
              suffix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
