import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useTranslate,
  CrudFilters,
  useUpdate,
} from "@refinedev/core";
import {
  List,
  useSelect,
  TextField,
  useTable,
  useEditableTable,
  SaveButton,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Row,
  Col,
  Card,
  Form,
  Input,
  FormProps,
  Select,
  Avatar,
  Menu,
  Button,
  Dropdown,
  Modal,
  message,
} from "antd";
import {
  SearchOutlined,
  UserOutlined,
  FormOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { IRegion, IRole, IStore, ITeam, IUser } from "interfaces";

export const AdminUserList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const [messageApi] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadings, setLoadings] = useState<Record<number, boolean>>({});

  const {
    tableProps,
    formProps,
    isEditing,
    onFinish,
    saveButtonProps,
    cancelButtonProps,
    searchFormProps,
    setId: setEditId,
  } = useEditableTable<IUser>({
    resource: "admin-users",
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q, email, team, region, store } = params;

      filters.push({
        field: "user_region.id",
        operator: "eq",
        value: region,
      });

      filters.push({
        field: "user_store.id",
        operator: "eq",
        value: store,
      });

      filters.push({
        field: "user_team.id",
        operator: "eq",
        value: team,
      });

      filters.push({
        field: "email",
        operator: "contains",
        value: email,
      });

      filters.push({
        operator: "or",
        value: [
          {
            field: "name",
            operator: "contains",
            value: q,
          },
          {
            field: "employeeId",
            operator: "contains",
            value: q,
          },
        ],
      });

      return filters;
    },
    syncWithLocation: false,
  });

  const { selectProps: storeSelectProps } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: roleSelectProps } = useSelect<IRole>({
    resource: "roles/admin",
    optionLabel: "name",
    optionValue: "id",
  });

  const moreMenu = (record: IUser) => (
    <Menu
      mode='vertical'
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      <Menu.Item
        key='accept'
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <FormOutlined
            style={{
              color: "#52c41a",
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        onClick={() => {
          setEditId?.(record.id);
        }}
      >
        {t("buttons.edit")}
      </Menu.Item>
    </Menu>
  );

  const { tableProps: userTableProps, searchFormProps: userSearchFormProps } =
    useTable({
      resource: "users",
      onSearch: (params: any) => {
        const filters: CrudFilters = [];
        const { q } = params;

        filters.push({
          operator: "or",
          value: [
            {
              field: "name",
              operator: "contains",
              value: q,
            },
            {
              field: "employeeId",
              operator: "contains",
              value: q,
            },
          ],
        });

        return filters;
      },
      syncWithLocation: false,
    });

  const { mutate } = useUpdate<IUser>();

  return (
    <Row gutter={[16, 16]}>
      <Col xl={24} xs={24}>
        <List
          headerButtons={
            <Button
              type='primary'
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              + Add User
            </Button>
          }
        >
          <Modal
            title='Add user'
            centered
            footer={null}
            width={1200}
            open={isModalOpen}
            onCancel={() => {
              setIsModalOpen(false);
            }}
          >
            <Space
              direction='vertical'
              size='middle'
              style={{ display: "flex" }}
            >
              <Form
                layout='vertical'
                {...userSearchFormProps}
                onValuesChange={() => {
                  userSearchFormProps.form?.submit();
                }}
              >
                <Form.Item name='q'>
                  <Input
                    addonBefore={<SearchOutlined />}
                    placeholder='Search user name or employee ID'
                    size='large'
                    title='Search by Employee ID'
                  />
                </Form.Item>
              </Form>
              <Table {...userTableProps} rowKey='id'>
                <Table.Column dataIndex='employeeId' title='Employee Id' />
                <Table.Column
                  dataIndex={["email", "name"]}
                  title='User'
                  render={(value: any, record: any) => {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "12px" }}>
                          <Avatar size='large' icon={<UserOutlined />} />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span style={{ fontWeight: 600 }}>{record.name}</span>
                          <TextField value={record.email} />
                        </div>
                      </div>
                    );
                  }}
                />
                <Table.Column
                  dataIndex={["team", "store", "region", "name"]}
                  title='Region'
                />
                <Table.Column
                  dataIndex={["team", "store", "name"]}
                  title='Store'
                />
                <Table.Column dataIndex={["team", "name"]} title='Team' />
                <Table.Column dataIndex={["role", "name"]} title='Role' />
                <Table.Column
                  title='Actions'
                  dataIndex='actions'
                  render={(_, record: IUser) => (
                    <Button
                      size='small'
                      type='primary'
                      disabled={["super admin", "store admin"].includes(
                        record?.role?.name
                      )}
                      loading={loadings[record.id]}
                      onClick={(e: any) => {
                        const _loadings = loadings;
                        _loadings[record.id] = true;
                        setLoadings(_loadings);

                        mutate(
                          {
                            resource: "users/upgrade",
                            values: {},
                            id: record.id,
                          },
                          {
                            onError: () => {
                              messageApi.open({
                                type: "error",
                                content: "Add user failed",
                              });
                            },
                            onSuccess: () => {
                              searchFormProps.form?.submit();
                              userSearchFormProps.form?.submit();
                            },
                            onSettled: () => {
                              const _loadings = loadings;
                              _loadings[record.id] = false;
                              setLoadings(_loadings);
                            },
                          }
                        );
                      }}
                    >
                      Add
                    </Button>
                  )}
                />
              </Table>
            </Space>
          </Modal>
          <Space direction='vertical' size='middle' style={{ display: "flex" }}>
            <Card title='Filter'>
              <Filter formProps={searchFormProps} />
            </Card>
            <Form
              {...formProps}
              onFinish={(values: any) => {
                const result = {
                  role: values.roleEdit,
                  stores: values.storesEdit.map((id: number) => ({ id })),
                };

                onFinish(result)
                  .then(() => {
                    setEditId(undefined);
                  })
                  .catch((error) => error);
              }}
            >
              <Table
                {...tableProps}
                rowKey='id'
                onRow={(record) => ({
                  // eslint-disable-next-line
                  onClick: (event: any) => {
                    if (event.target.nodeName === "TD") {
                      setEditId && setEditId(record.id);
                    }
                  },
                })}
              >
                <Table.Column dataIndex='id' title='ID' width={"100px"} />
                <Table.Column
                  dataIndex='employeeId'
                  title='EMPLOYEE ID'
                  width='180px'
                />
                <Table.Column
                  width={"250px"}
                  dataIndex={["email", "name"]}
                  title='USER'
                  render={(value: any, record: IUser) => {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "12px" }}>
                          <Avatar size='large' icon={<UserOutlined />} />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span style={{ fontWeight: 600 }}>{record.name}</span>
                          <TextField value={record.email} />
                        </div>
                      </div>
                    );
                  }}
                />
                <Table.Column
                  width={"600px"}
                  dataIndex={["stores"]}
                  title='StORE ACCESS'
                  render={(value: any, record: IUser) => {
                    if (isEditing(record.id)) {
                      return (
                        <Form.Item
                          style={{
                            marginBottom: "0px",
                          }}
                          name='storesEdit'
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                          initialValue={record?.stores?.map(
                            (store: IStore) => store.id
                          )}
                        >
                          <Select
                            {...storeSelectProps}
                            mode='multiple'
                            allowClear
                            placeholder={t("Select Store")}
                          />
                        </Form.Item>
                      );
                    } else {
                      return (
                        <span>
                          {value.map((store: IStore) => store.name).join(", ")}
                        </span>
                      );
                    }
                  }}
                />
                <Table.Column
                  dataIndex={["role"]}
                  title='CMS ROLE'
                  render={(value: any, record: IUser) => {
                    if (isEditing(record.id)) {
                      return (
                        <Form.Item
                          style={{
                            marginBottom: "0px",
                          }}
                          name='roleEdit'
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                          initialValue={record?.role?.id}
                        >
                          <Select
                            {...roleSelectProps}
                            allowClear
                            placeholder={t("Select Role")}
                          />
                        </Form.Item>
                      );
                    } else {
                      return <span>{value?.name}</span>;
                    }
                  }}
                />
                <Table.Column
                  title='ACTIONS'
                  dataIndex='actions'
                  render={(_text, record: IUser) => {
                    if (isEditing(record.id)) {
                      return (
                        <Space>
                          <SaveButton {...saveButtonProps} size='small' />
                          <Button {...cancelButtonProps} size='small'>
                            {t("buttons.cancel")}
                          </Button>
                        </Space>
                      );
                    }
                    return (
                      <Dropdown overlay={moreMenu(record)} trigger={["click"]}>
                        <MoreOutlined
                          onClick={(e) => e.stopPropagation()}
                          style={{
                            fontSize: 24,
                          }}
                        />
                      </Dropdown>
                    );
                  }}
                />
              </Table>
            </Form>
          </Space>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();

  const { selectProps: regionSelectProps } = useSelect<IRegion>({
    resource: "regions",
    optionLabel: "name",
    optionValue: "id",
  });
  const { selectProps: teamSelectProps } = useSelect<ITeam>({
    resource: "teams",
    optionLabel: "name",
    optionValue: "id",
  });
  const { selectProps: storeSelectProps } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Form
      layout='vertical'
      {...props.formProps}
      onValuesChange={() => {
        props.formProps.form?.submit();
      }}
    >
      <Row gutter={[10, 0]} align='bottom'>
        <Col xl={6} md={6} xs={12}>
          <Form.Item label={t("Region")} name={["region"]}>
            <Select
              {...regionSelectProps}
              allowClear
              placeholder={t("Select Region")}
            />
          </Form.Item>
        </Col>
        <Col xl={6} md={6} xs={12}>
          <Form.Item label={t("Store")} name={["store"]}>
            <Select
              {...storeSelectProps}
              allowClear
              placeholder={t("Select Store")}
            />
          </Form.Item>
        </Col>
        <Col xl={6} md={6} xs={12}>
          <Form.Item label={t("Team")} name='team'>
            <Select
              {...teamSelectProps}
              allowClear
              placeholder={t("Select Team")}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={12}>
          <Form.Item name='q' noStyle>
            <Input
              style={{
                width: "400px",
              }}
              placeholder={t("Search user or employee ID")}
              suffix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
