import { useMemo, useState } from "react";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Card, Col, Dropdown, Menu, MenuProps, Row, Space, Typography } from "antd";
import { IPollUsage, IRegion } from "interfaces";
import { DownOutlined } from "@ant-design/icons";
import { Bar, BarConfig } from "@ant-design/charts";

export const PollUsages: React.FC = () => {
  const t = useTranslate();
  const [selectedKey, setSelectedKey] = useState('0');

  const API_URL = useApiUrl();
  const url = `${API_URL}/dashboard/poll-usage/${selectedKey !== '0' ? selectedKey : ''}`;
  const { data } = useCustom<{
    data: IPollUsage;
  }>({ url, method: "get" });

  const { Text, Title } = Typography;

  const result = useMemo(() => {
    return data?.data.data;
  }, [data]);

  const userStat = (count: number | undefined, title: string) => {
    return (
      <Card
        bodyStyle={{
          padding: 50,
          textAlign: "center",
        }}
      >
        <Title>{(count ?? 0).toLocaleString()} </Title>
        <Text>{t(title)}</Text>
      </Card>
    );
  };

  const configStore: BarConfig = {
    data: result?.pollUsageByStore,
    xField: "total",
    yField: "store",
    barWidthRatio: 0.1,
    meta: {
      store: {
        alias: "store",
      },
      total: {
        alias: "total",
      },
    },
  };

  const regionUrl = `${API_URL}/regions`;
  const { data: regionData } = useCustom<{
    data: IRegion[];
  }>({ url: regionUrl, method: "get" });

  const regionResult = useMemo(() => {
    return regionData?.data.data;
  }, [regionData]);

  const regionList = regionResult?.map((item: any) => {
    return {
      key: item.id.toString(),
      label: <span>{item.name}</span>,
    };
  }) as { key: string; label: JSX.Element }[];

  const regions: MenuProps["items"] = regionList ? [
    {
      key: 0,
      label: <span>All Region</span>,
    },
    ...regionList,
  ] : [];

  const selectedRegion = regionResult?.find((region) => region.id.toString() === selectedKey);
  const label = selectedRegion ? <span>{selectedRegion.name}</span> : 'All Regions';
  const handleMenuSelect = ({ key }: { key: string }) => {
    setSelectedKey(key);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title level={3}>{t("Poll Usage")}</Title>
      </div>
      <div style={{ marginTop: "16px", marginBottom: "26px" }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col xl={8} lg={12} md={24} sm={24} xs={24}>
            {userStat(result?.pollUsage.scheduled, "Poll Scheduled")}
          </Col>
          <Col xl={8} lg={12} md={24} sm={24} xs={24}>
            {userStat(result?.pollUsage?.onGoing, "On-going Poll")}
          </Col>
          <Col xl={8} lg={12} md={24} sm={24} xs={24}>
            {userStat(result?.pollUsage?.pendingPublish, "Poll Pending Publish")}
          </Col>
        </Row>
        {result?.pollUsageByStore &&
          <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card
                title={
                  <div style={{ display: "flex", flexDirection: "column", paddingTop: "10px" }}>
                    <span>Total Poll Usage by Store</span>
                    <Text type='secondary'>Past 12 months</Text>
                  </div>
                }
                extra={
                  <Dropdown
                    overlay={
                      <Menu onClick={handleMenuSelect}>
                        {regions.map((region) => (
                          <Menu.Item key={region?.key}>{
                            region?.key ?
                              regionResult?.find((a) => a.id.toString() === region?.key)?.name
                              : 'All Region'
                          }</Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Space>
                        {label}
                        <DownOutlined />
                      </Space>
                    </span>
                  </Dropdown>
                }
              >
                <div style={{ padding: "20px" }}>
                  <Bar {...configStore} />
                </div>
              </Card>
            </Col>
          </Row>
        }
      </div>
    </>
  );
};
