import { useState, useCallback } from "react";
import { LOGIN_API, OKTA_AUTH_BY_TOKEN } from "./apiConfig";

type User = {
  name: string;
  email: string;
  role: string;
};

type ApiError = {
  message: string;
};

type AuthResponse = User | null;

type UseApiServiceReturn = {
  isLoading: boolean;
  error: ApiError | null;
  authByOktaToken: (token: string) => Promise<AuthResponse>;
  authByEmail: (email: string, password: string) => Promise<AuthResponse>;
};

const useApiService = (): UseApiServiceReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const authByOktaToken = useCallback(
    async (token: string): Promise<AuthResponse> => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${OKTA_AUTH_BY_TOKEN}?token=${token}`);

        if (!response.ok) {
          throw new Error("Failed to authenticate user.");
        }

        const apiRes = await response.json();
        return apiRes.data;
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const authByEmail = useCallback(
    async (email: string, password: string): Promise<AuthResponse> => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(LOGIN_API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });

        if (!response.ok) {
          throw new Error("Failed to authenticate user.");
        }

        const apiRes = await response.json();
        return apiRes.data;
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return {
    isLoading,
    error,
    authByOktaToken,
    authByEmail,
  };
};

export default useApiService;
