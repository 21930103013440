import React, { useEffect, useState } from "react";
import {
  HttpError,
  IResourceComponentsProps,
  useShow,
  useUpdate,
} from "@refinedev/core";
import { Show, TagField, useTable, List } from "@refinedev/antd";
import {
  Button,
  Divider,
  Select,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import { IPollSection, ITeam } from "interfaces";
import { PollResult } from "./poll-result";
import moment from "moment";

const { Title } = Typography;

interface ExpandedDataType {
  key: React.Key;
  totalVotes: string;
  invitedParticipants: number;
  dayLeft: number;
}

export const PollShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const [selectedSection, setSelectedSection] = useState<IPollSection>();

  const record = data?.data;
  const options = record?.pollSections?.map((item: IPollSection) => ({
    label: new Date(item.startDate).toISOString().slice(0, 7),
    value: item.id,
  }));

  options?.sort((a: any, b: any) => {
    const startDateA = new Date(a.label);
    const startDateB = new Date(b.label);
    return startDateB.getTime() - startDateA.getTime();
  });
  const sectionOptions = options;

  useEffect(() => {
    if (options !== null) {
      const defaultSection =
        options && options.length > 0 ? options[0].value : null;
      setSelectedSection(
        record?.pollSections?.find(
          (obj: IPollSection) => obj.id === defaultSection
        )
      );
    }
  }, [options, record]);

  const { tableProps } = useTable<IPollSection, HttpError>({
    resource: "poll-sections",
    filters: {
      permanent: [
        {
          field: "id",
          operator: "eq",
          value: record?.pollSections?.[0]?.id ?? 0,
        },
      ],
    },
    syncWithLocation: false,
  });

  const expandedRowRender = () => {
    const columns: TableColumnsType<ExpandedDataType> = [
      { title: "TOTAL VOTES", dataIndex: "totalVotes", key: "totalVotes" },
      {
        title: "INVITED PARTICIPANTS",
        dataIndex: "invitedParticipants",
        key: "invitedParticipants",
      },
      {
        title: "DAY LEFT",
        dataIndex: "dayLeft",
        key: "dayLeft",
      },
    ];

    const sectionData = [
      {
        key: "1",
        totalVotes: "100 (30%)",
        invitedParticipants: 100,
        dayLeft: 10,
      },
    ];

    return (
      <div>
        <Table columns={columns} dataSource={sectionData} pagination={false} />
      </div>
    );
  };

  const { mutate } = useUpdate();

  return (
    <Show isLoading={isLoading} title='Poll Details'>
      <Title level={4}>Poll Section</Title>
      {sectionOptions && selectedSection ? (
        <Select
          options={sectionOptions}
          placeholder='Select Section'
          style={{ width: 300 }}
          defaultValue={selectedSection.id}
          onSelect={(value) => {
            setSelectedSection(
              record?.pollSections?.find(
                (obj: IPollSection) => obj.id === value
              )
            );
          }}
        />
      ) : (
        <div>No Section</div>
      )}

      <Divider />
      <List
        title='Poll Info'
        breadcrumb={false}
        headerButtons={[
          <Button
            disabled={selectedSection?.isEnded}
            key={"terminate"}
            danger
            onClick={() => {
              mutate({
                resource: "poll-sections/terminate",
                values: {},
                id: selectedSection?.id ?? 0,
              });
            }}
          >
            Terminate
          </Button>,
        ]}
      >
        <Table
          {...tableProps}
          rowKey='id'
          expandable={{ expandedRowRender, defaultExpandAllRows: true }}
          pagination={false}
        >
          <Table.Column
            dataIndex={["stores"]}
            title='Store'
            render={(value: any[]) => {
              const uniqueStoreNames = Array.from(
                new Set(record?.teams.map((team: ITeam) => team.store.name))
              );
              return uniqueStoreNames.join(", ");
            }}
          />
          <Table.Column
            dataIndex='teams'
            title='Team'
            render={(value: any[]) => (
              <>
                {record?.teams?.map((item: ITeam) => (
                  <TagField value={item?.name} key={item?.name} />
                ))}
              </>
            )}
          />
          <Table.Column
            dataIndex='theme'
            title='Theme'
            render={(value: any[]) => <>{record?.themes?.length}</>}
          />
          <Table.Column
            dataIndex='startDate'
            title='Start Date'
            render={(startDate) =>
              moment(selectedSection?.startDate).format("YYYY-MM-DD HH:mm:ss")
            }
          />
          <Table.Column
            dataIndex='endDate'
            title='End Date'
            render={(endDate) =>
              moment(selectedSection?.endDate).format("YYYY-MM-DD HH:mm:ss")
            }
          />
          <Table.Column
            dataIndex='mode'
            title='Mode'
            render={(value: string) => <TagField value={record?.mode} />}
          />
          <Table.Column
            dataIndex='status'
            title='Status'
            render={(value: string) => <TagField value={record?.status} />}
          />
        </Table>
      </List>

      <Divider />
      {selectedSection?.id && record?.id && (
        <PollResult
          sectionId={selectedSection?.id}
          pollId={parseInt(record?.id?.toString() ?? "", 10)}
          isPublished={selectedSection?.isPublished}
        />
      )}
    </Show>
  );
};
