import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
} from "@refinedev/antd";
import { Table, Space, Tabs } from "antd";
import type { TabsProps } from "antd";

export const LanguageList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Default language",
      children: (
        <List>
          <Table {...tableProps} rowKey='id'>
            <Table.Column dataIndex='id' title='Id' />
            <Table.Column dataIndex='name' title='Name' />
            <Table.Column dataIndex='slug' title='Slug' />
            <Table.Column dataIndex='currency' title='Currency' />
            <Table.Column
              title='Actions'
              dataIndex='actions'
              render={(_, record: BaseRecord) => (
                <Space>
                  <EditButton hideText size='small' recordItemId={record.id} />
                  <ShowButton hideText size='small' recordItemId={record.id} />
                  <DeleteButton
                    hideText
                    size='small'
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      ),
    },
  ];

  return <Tabs defaultActiveKey='1' items={items} onChange={onChange} />;
};
