import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Layout as AntdLayout, Button } from "antd";
import "./styles.css";
import OktaAuth from "@okta/okta-auth-js";
import { TOKEN_KEY } from "utils/authProvider";
import { useNotification } from "@refinedev/core";
import useApiService from "utils/useApiService";

interface LoginProps {
  oktaAuth: OktaAuth;
}

export const Login: React.FC<LoginProps> = ({ oktaAuth }) => {
  const [isCallback, setIsCallback] = useState(false);
  const { open } = useNotification();
  const { error, authByOktaToken } = useApiService();
  const hasEffectRunRef = useRef(false); //block the multiple render

  useEffect(() => {
    if (!hasEffectRunRef.current) {
      const handleCallback = async () => {
        try {
          await oktaAuth.handleRedirect();
        } catch (error) {
          console.log(error);
        }

        try {
          const token = await oktaAuth.getAccessToken();
          if (!token) {
            throw new Error("Fail to fetch token");
          }

          if (token) {
            const user = await authByOktaToken(token);
            if (!user) {
              throw new Error("Fail to authenticate user");
            }

            if (user) {
              localStorage.setItem(TOKEN_KEY, JSON.stringify(user));
              window.location.href = "/";
            } else if (error) {
              open?.({
                type: "error",
                message: error.message,
                description: "Login Fail",
                key: "okta-fail-get-user",
              });
            }
          }
        } catch (error) {
          open?.({
            type: "error",
            message: "Fail to login Okta / API service",
            description: "Login Fail",
            key: "okta-fail-get-token",
          });
        }
      };

      if (oktaAuth.isLoginRedirect()) {
        setIsCallback(true);
        hasEffectRunRef.current = true;
        handleCallback();
      }
    }
  }, [authByOktaToken, error, oktaAuth, open]);

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  return (
    <AntdLayout className='layout'>
      <Row
        justify='center'
        align='middle'
        style={{
          height: "100vh",
          background:
            "linear-gradient(276.69deg, #BF2E1A 10.12%, #932010 87.52%)",
        }}
      >
        <Col xs={22}>
          <div className='container'>
            <div className='logo'>DFS</div>
            <h1>DFS MySA CMS</h1>

            {isCallback ? (
              <div style={{ color: "#000", textAlign: "center" }}>
                Processing Single Sign On ...{" "}
              </div>
            ) : (
              <Button
                style={{ width: "153px", color: "white" }}
                onClick={login}
                htmlType='submit'
                block
                className='okta-button'
              >
                Sign in with Okta
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
