import { CanParams, CanReturnType } from "@refinedev/core";
import { authProvider } from "./authProvider";

export const accessControlProvider = {
  can({ resource, action }: CanParams): Promise<CanReturnType> {
    return new Promise(async (resolve, _reject) => {
      const permissions = (await authProvider?.getPermissions?.()) as string[];
      resolve({
        can: permissions?.includes(`${resource}.${action}`),
      });
    });
  },
};
