import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select } from "antd";
import { ILanguage, IStore } from "interfaces";

export const RegionCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, onFinish } = useForm();
  const { selectProps: langSelectProps } = useSelect<ILanguage>({
    resource: "languages",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: locationSelectProps } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical"
        onFinish={(values: any) => {
          const result = {
            name: values.name,
            language: values.language,
            stores: values.stores.map((id: number) => ({ id })),
          }

          onFinish(result).catch((error) => error)
        }}
      >
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Language"
          name="language"
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Select
            {...langSelectProps}
            allowClear
            placeholder='Select Language'
          />
        </Form.Item>
        <Form.Item
          label="Store(s)"
          name="stores"
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Select
            {...locationSelectProps}
            allowClear
            mode="multiple"
            placeholder='Select Store(s)'
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
