import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Checkbox, Select } from "antd";
import { IRole, IStore } from "interfaces";

export const AdminUserCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();

  const usersData = queryResult?.data?.data;

  const { selectProps: roleSelectProps } = useSelect<IRole>({
    resource: "roles/admin",
    optionLabel: "name",
    optionValue: "id",
  });
  const { selectProps: storesSelectProps } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout='vertical'
        onFinish={(values: any) => {
          const result = {
            ...values,
            role: { id: values.roleEdit },
            stores: values.storesEdit.map((id: number) => ({ id })),
          };
          onFinish(result).catch((error) => error);
        }}
      >
        <Form.Item
          label='Name'
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Email'
          name={["email"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Password'
          name={["password"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Is Active'
          valuePropName='checked'
          name={["isActive"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
        <Form.Item
          label={t("Stores")}
          name='storesEdit'
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={usersData?.stores?.map((store: IStore) => store.id)}
        >
          <Select
            {...storesSelectProps}
            mode='multiple'
            allowClear
            placeholder={t("Select Store")}
          />
        </Form.Item>
        <Form.Item
          label={t("Role")}
          name='roleEdit'
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={usersData?.role?.id}
        >
          <Select
            {...roleSelectProps}
            allowClear
            placeholder={t("Select Role")}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
