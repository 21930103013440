import React from "react";
import { useTable, List, useSelect } from "@refinedev/antd";
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormProps,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import { IPollResult, IPollResultGroup, ITheme, IUser } from "interfaces";
import { CrudFilters, useUpdate } from "@refinedev/core";

const { Title } = Typography;
export interface PollResultProps {
  pollId: number;
  sectionId: number;
  isPublished: boolean;
}

export const PollResult: React.FC<PollResultProps> = ({
  pollId,
  sectionId,
  isPublished,
}) => {
  const { tableProps, searchFormProps } = useTable({
    resource: "poll-results/group",
    syncWithLocation: false,
    filters: {
      permanent: [
        {
          field: "poll_result_section.id",
          operator: "eq",
          value: sectionId ?? 0,
        },
      ],
    },
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { theme, candidate } = params;

      filters.push({
        field: "poll_result_theme.id",
        operator: "eq",
        value: theme,
      });

      filters.push({
        field: "poll_result_candidate.id",
        operator: "eq",
        value: candidate,
      });

      return filters;
    },
  });

  const { mutate } = useUpdate();

  return (
    <Row gutter={[16, 16]}>
      <Col xl={24} lg={24} xs={24}>
        <Title level={4}>Poll Results</Title>
        <Filter
          formProps={searchFormProps}
          pollId={pollId}
          sectionId={sectionId}
        />
      </Col>
      <Col xl={24} xs={24}>
        <List
          breadcrumb={false}
          title=''
          canCreate={false}
          headerButtons={[
            <Button
              disabled={isPublished}
              key={"publish"}
              color='primary'
              onClick={() => {
                mutate({
                  resource: "poll-sections/winner/publish",
                  values: {},
                  id: sectionId,
                  successNotification: (data, values) => {
                    return {
                      description: `The voting result was published successfully`,
                      message: "The voting result was published successfully",
                      type: "success",
                    };
                  },
                  errorNotification(data) {
                    console.log(data?.response?.data?.meta?.error);
                    return {
                      type: "error",
                      description: "Failed to publish the poll result",
                      message: data?.response?.data?.meta?.error,
                    };
                  },
                });
              }}
            >
              {isPublished ? "Published" : "Publish"}
            </Button>,
          ]}
        >
          <Table
            {...tableProps}
            rowKey={(record) => {
              return record.pollTheme.id + "-" + record.candidate.id;
            }}
            expandable={{
              expandedRowRender: expandedRowRender,
              defaultExpandAllRows: false,
            }}
          >
            <Table.Column
              dataIndex={["pollTheme", "title"]}
              title='THEME'
              render={(value) => {
                return <Tag color='blue'>{value}</Tag>;
              }}
            />
            <Table.Column dataIndex={["candidate", "name"]} title='NAME' />
            <Table.Column
              dataIndex={["voteNum"]}
              title='VOTE'
              render={(value, record: any) => (
                <>
                  <span>{value}</span>
                  <span style={{ marginLeft: "4px", color: "gray" }}>
                    ({Math.ceil((value * 100) / record.totalVoteNum)}%)
                  </span>
                </>
              )}
            />
            <Table.Column
              dataIndex={["commentNum"]}
              title='COMMENT'
              render={(value) => <>{value} comment</>}
            />
            <Table.Column
              dataIndex={["candidate", "isWinner"]}
              title='WINNER'
              render={(value, record: IPollResultGroup) => {
                console.log(record.pollTheme.id + "-" + record.candidate.id);
                console.log(value);
                return (
                  <Checkbox
                    defaultChecked={value}
                    onChange={(v) => {
                      mutate({
                        resource: "poll-sections/winner",
                        values: {
                          type: v.target.checked ? "add" : "remove",
                          winner: { id: record.candidate.id },
                          theme: { id: record.pollTheme.id },
                        },
                        id: record.pollSection.id,
                      });
                    }}
                  />
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

interface FilterProps {
  formProps: FormProps<any>;
  pollId?: number;
  sectionId?: number;
}
const Filter: React.FC<FilterProps> = ({ formProps, pollId, sectionId }) => {
  const { selectProps: themeSelectProps } = useSelect<ITheme>({
    resource: "poll-themes",
    optionLabel: "title",
    optionValue: "id",
    filters: [
      {
        field: "theme_poll.id",
        operator: "eq",
        value: pollId,
      },
    ],
  });

  const { queryResult } = useSelect({
    resource: "poll-results",
    filters: [
      {
        field: "poll_result_section.id",
        operator: "eq",
        value: sectionId ?? 0,
      },
    ],
  });

  const candidates = queryResult?.data?.data?.map((item) => item.candidate);
  const uniqueCandidates = candidates?.reduce((acc, candidate) => {
    const candidateExists = acc.some(
      (item: IUser) => item.id === candidate?.id
    );
    if (!candidateExists && candidate) {
      acc.push(candidate);
    }
    return acc;
  }, []);

  const options = uniqueCandidates?.map((item: IUser) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <Form
      layout='vertical'
      {...formProps}
      onValuesChange={() => {
        formProps.form?.submit();
      }}
    >
      <Row gutter={[10, 0]} align='bottom'>
        <Col xl={6} md={6} xs={12}>
          <Form.Item label={"Theme"} name={["theme"]}>
            <Select
              {...themeSelectProps}
              allowClear
              placeholder='Select Theme'
            />
          </Form.Item>
        </Col>
        <Col xl={6} md={6} xs={12}>
          <Form.Item label={"Candidate"} name={["candidate"]}>
            <Select
              options={options}
              allowClear
              placeholder='Select Candidate'
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const VoteDetailTable: React.FC<{
  record: IPollResultGroup;
}> = ({ record }) => {
  const { tableProps: postTableProps } = useTable<IPollResult>({
    resource: "poll-results",
    permanentFilter: [
      {
        field: "poll_result_candidate.id",
        operator: "eq",
        value: record.candidate.id,
      },
      {
        field: "poll_result_theme.id",
        operator: "eq",
        value: record.pollTheme.id,
      },
    ],
    syncWithLocation: false,
  });

  const { mutate } = useUpdate();

  return (
    <div>
      <Table {...postTableProps} rowKey='id'>
        <Table.Column key='voter' dataIndex={["voter", "name"]} title='VOTER' />
        <Table.Column key='comment' dataIndex='comment' title='COMMENT' />
        <Table.Column
          key='createdAt'
          dataIndex='createdAt'
          title='POST DATETIME'
          render={(value) => {
            return new Date(value).toLocaleString();
          }}
        />
        <Table.Column
          key='hide'
          dataIndex='hide'
          title='HIDE ON APP'
          render={(value, record: IPollResult) => {
            return (
              <div style={{ display: "flex" }}>
                <Switch
                  defaultChecked={value}
                  onChange={(v) => {
                    mutate({
                      resource: "poll-results",
                      values: {
                        hide: v,
                      },
                      id: record.id,
                    });
                  }}
                />
                <div style={{ marginLeft: "8px" }}>Hide</div>
              </div>
            );
          }}
        />
      </Table>
    </div>
  );
};

const expandedRowRender: any = (record: IPollResultGroup) => {
  return <VoteDetailTable record={record} />;
};
