import React, { useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Radio,
  Divider,
  Space,
  Button,
  Descriptions,
  FormListFieldData,
} from "antd";
import dayjs from "dayjs";
import { IPoll, IStore } from "interfaces";
import Paragraph from "antd/es/typography/Paragraph";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { SortableList } from "components";
import Badge from "components/poll/Badge";

export const PollCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form, onFinish } = useForm<IPoll>();
  const [selectedStore, setSelectedStore] = useState<IStore>();

  const { selectProps: storesSelectProps, queryResult } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "name",
    optionValue: "id",
  });

  const { RangePicker } = DatePicker;
  const modeValue = Form.useWatch("mode", form);
  const startDayFromMonthEndValue = Form.useWatch("startDayFromMonthEnd", form);
  const endPollRepeatDateValue = Form.useWatch("endPollRepeatDate", form);

  const endOfMonth = () => {
    const endOfMonth = moment().endOf("month");
    if (endPollRepeatDateValue && endPollRepeatDateValue < endOfMonth) {
      return endPollRepeatDateValue;
    }

    return endOfMonth;
  };

  const nextPeriodStartDay = moment()
    .endOf("month")
    .subtract(startDayFromMonthEndValue, "days");

  const handleStoreChange = (selectedStore: any) => {
    const stores = queryResult?.data?.data;
    const store = stores?.find((store) => store.id === selectedStore);
    if (store) {
      const teams = store.teams.map((team) => team.id);
      form.setFieldsValue({ teams });
      setSelectedStore(store);
    }
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        onFinish={(values: any) => {
          values.themes?.forEach((theme: any, index: number) => {
            theme.order = index + 1;
            return theme;
          });
          const result = {
            teams: values.teams.map((id: number) => ({ id })),
            mode: values.mode,
            oneOffStartDay: values.pollPeriod?.[0],
            oneOffEndDay: values.pollPeriod?.[1],
            startDayFromMonthEnd: values.startDayFromMonthEnd,
            endPollRepeatDate: values.endPollRepeatDate,
            themes: values.themes,
            timezone: selectedStore?.timezone
          };

          onFinish(result).catch((error) => error);
        }}
        layout='vertical'
      >
        <Descriptions title='Participant'>
          <Descriptions.Item>
            Who would you like to invite to the poll?
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <Form.Item
          label='Select all teams within store'
          name='store'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...storesSelectProps}
            allowClear
            placeholder='Select Store'
            onChange={handleStoreChange}
          />
        </Form.Item>
        <Form.Item
          label='Selected team(s)'
          name='teams'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={selectedStore?.teams?.map((team) => ({
              label: team.name,
              value: team.id,
            }))}
            allowClear
            mode='multiple'
            placeholder='Select Team(s)'
          />
        </Form.Item>

        <Divider />
        <Descriptions title='Theme'>
          <Descriptions.Item>
            What are your participants voting for?
          </Descriptions.Item>
        </Descriptions>
        <Divider />

        <Form.List name='themes'>
          {(fields: FormListFieldData[], { add, remove, move }) => {
            let items: any[] = fields;

            items.forEach((item) => {
              console.log(item);
              item.id = item.key.toString();
              return item;
            });

            return (
              <>
                <SortableList
                  items={items}
                  move={move}
                  renderItem={(item) => {
                    return (
                      <SortableList.Item id={item.id}>
                        <SortableList.DragHandle />
                        <Space
                          key={item.key}
                          style={{ display: "flex", alignItems: "center" }}
                          align='center'
                        >
                          <Form.Item
                            {...item}
                            name={[item.name, "title"]}
                            key={`${item.key}-theme`}
                            style={{
                              width: "60vh",
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter title",
                              },
                            ]}
                          >
                            <Input placeholder='Theme' />
                          </Form.Item>
                          <Form.Item
                            style={{
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                            {...item}
                            name={[item.name, "badge"]}
                            key={`${item.key}-badge`}
                            rules={[
                              {
                                required: true,
                                message: "Please select badge",
                              },
                            ]}
                          >
                            <Badge
                              value={form.getFieldValue([item.name, "badge"])}
                              onChange={(value) => {
                                form.setFields([
                                  { name: [item.name, "badge"], value },
                                ]);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            style={{
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <MinusCircleOutlined
                              onClick={() => remove(item.name)}
                            />
                          </Form.Item>
                        </Space>
                      </SortableList.Item>
                    );
                  }}
                />
                <Form.Item>
                  <Button
                    type='dashed'
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add item
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Divider />

        <Descriptions title='Frequency'>
          <Descriptions.Item>
            How often would you like this poll to happen?
          </Descriptions.Item>
        </Descriptions>
        <Form.Item
          label='Poll Mode'
          name='mode'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group
            onChange={() => {
              form.resetFields([
                "startDayFromMonthEnd",
                "pollPeriod",
                "endPollRepeatDate",
                "oneOffStartDay",
                "oneOffEndDay",
              ]);
            }}
          >
            <Radio.Button value='Month-end'>Month-end</Radio.Button>
            <Radio.Button value='One-off'>One-off</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {modeValue === "Month-end" ? (
          <>
            <Paragraph>
              System to automatically trigger poll regularly
            </Paragraph>
            <Divider />

            <Form.Item
              label='Start day(s) before month-end'
              name='startDayFromMonthEnd'
              dependencies={["mode"]}
              rules={[
                ({ getFieldValue }) => ({
                  required: getFieldValue("mode") === "Month-end",
                }),
              ]}
            >
              <Input
                size='small'
                style={{ width: 50 }}
                maxLength={3}
                onChange={() => {
                  form.resetFields(["endPollRepeatDate"]);
                }}
              />
            </Form.Item>
            {startDayFromMonthEndValue ? (
              <Paragraph>
                Next poll period:{" "}
                {nextPeriodStartDay.format("YYYY-MM-DD 00:00:00")} to{" "}
                {endOfMonth().format("YYYY-MM-DD 23:59:59")}
              </Paragraph>
            ) : (
              ""
            )}
            <Form.Item
              label='Input date to end poll repeat'
              name='endPollRepeatDate'
              getValueProps={(value) => ({
                value: value ? dayjs(value) : undefined,
              })}
              rules={[
                ({ getFieldValue }) => ({
                  required: getFieldValue("mode") === "Month-end",
                }),
              ]}
            >
              <DatePicker
                disabledDate={(d) =>
                  !d || d.isBefore(nextPeriodStartDay.format("YYYY-MM-DD"))
                }
              />
            </Form.Item>
            {selectedStore && <span> Timezone: {selectedStore.timezone}</span>}
          </>
        ) : null}
        {modeValue === "One-off" ? (
          <>
            <Paragraph>
              Poll will only be started once during defined time period
            </Paragraph>
            <Divider />
            <Form.Item
              label='Poll period'
              name='pollPeriod'
              rules={[
                ({ getFieldValue }) => ({
                  required: getFieldValue("mode") === "One-off",
                }),
              ]}
            >
              <RangePicker style={{ width: "50%" }} />
            </Form.Item>
            {selectedStore && <span> Timezone: {selectedStore.timezone}</span>}
          </>
        ) : null}
      </Form>
    </Create>
  );
};
