import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Checkbox, Select } from "antd";
import { IStore, ITeam } from "interfaces";

export const AdminUserEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();
  const t = useTranslate();

  const usersData = queryResult?.data?.data;

  const { selectProps: storeSelectProps } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "name",
    optionValue: "id",
  });
  const { selectProps: teamSelectProps } = useSelect<ITeam>({
    resource: "teams",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout='vertical'
        onFinish={(values: any) => {
          const result = {
            ...values,
            team: values.teamEdit,
            stores: values.storesEdit.map((id: number) => ({ id })),
          };
          onFinish(result).catch((error) => error);
        }}
      >
        <Form.Item
          label='Id'
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label='Name'
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Email'
          name={["email"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Is Active'
          valuePropName='checked'
          name={["isActive"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
        <Form.Item
          label={t("Store")}
          name='storesEdit'
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={usersData?.stores?.map((store: IStore) => store.id)}
        >
          <Select
            {...storeSelectProps}
            mode='multiple'
            allowClear
            placeholder={t("Select Store")}
          />
        </Form.Item>
        <Form.Item
          label='Team'
          name='teamEdit'
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={usersData?.team?.id}
        >
          <Select
            {...teamSelectProps}
            allowClear
            placeholder={t("Select Team")}
          />
        </Form.Item>
        <Form.Item label='Password' name={["password"]}>
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
