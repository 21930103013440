import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Select } from "antd";
import { IService } from "interfaces";
import { PermissionAction } from "enums";

export const PermissionCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();
  const { selectProps: servieSelectProps } = useSelect<IService>({
    resource: "services",
    optionLabel: "name",
    optionValue: "id",
  });

  const permissionOptions = Object.keys(PermissionAction).map((key) => ({
    label: key,
    value: PermissionAction[key as keyof typeof PermissionAction],
  }));

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Service"
          name="service"
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Select
            {...servieSelectProps}
            allowClear
            placeholder='Select Service'
          />
        </Form.Item>
        <Form.Item
          label="Action"
          name={["action"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            placeholder='Select Service'
            options={permissionOptions}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
