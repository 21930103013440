import React, { useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Switch } from "antd";
import { IRegion, ITeam } from "interfaces";

export const StoreCreate: React.FC<IResourceComponentsProps> = () => {
  const [commissionType, setCommissionType] = useState<"percentage" | "amount">(
    "percentage"
  );

  const { formProps, saveButtonProps, onFinish } = useForm();
  const { selectProps: regionSelectProps } = useSelect<IRegion>({
    resource: "regions",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: teamsSelectProps } = useSelect<ITeam>({
    resource: "teams",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout='vertical'
        onFinish={(values: any) => {
          const result = {
            ...values,
            commissionType: commissionType,
            teams: values.teams.map((id: number) => ({ id })),
          };

          onFinish(result).catch((error) => error);
        }}
      >
        <Form.Item
          label='Region'
          name='region'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...regionSelectProps}
            allowClear
            placeholder='Select Region'
          />
        </Form.Item>
        <Form.Item
          label='Name'
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Team(s)'
          name='teams'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...teamsSelectProps}
            allowClear
            mode='multiple'
            placeholder='Select Team(s)'
          />
        </Form.Item>
        <Form.Item label='Commission Type' name='commission_type'>
          <Switch
            checked={commissionType !== "percentage"}
            onChange={(v) => {
              if (v) {
                setCommissionType("amount");
              } else {
                setCommissionType("percentage");
              }
            }}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
