import DfsIcon from './DFS_Group_logo.png';

type IconProps = {
    size?: number;
}

const DFSLogo = ({ size }: IconProps) => {
    const iconSize = size ? size : 24; // Default icon size is 24px
    return (
        <img src={DfsIcon} alt="My Icon" width={iconSize} height={iconSize} />
    );
}

export default DFSLogo;
