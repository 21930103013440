import { Show, NumberField, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useShow, useParsed } from "@refinedev/core";
import { IStore, IUser } from "interfaces";
import { Typography } from "antd";

const { Title } = Typography;

export const AdminUserShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParsed();

  const { queryResult } = useShow<IUser>({
    resource: "admin-users",
    id,
  });

  const { data, isLoading }: any = queryResult;
  console.log(data);
  return (
    <Show title='Admin user' resource='admin-users' isLoading={isLoading}>
      <Title level={5}>Id</Title>
      <NumberField value={data?.data?.id ?? ""} />
      <Title level={5}>Employee Id</Title>
      <TextField value={data?.data?.employeeId ?? ""} />
      <Title level={5}>Name</Title>
      <TextField value={data?.data?.name ?? ""} />
      <Title level={5}>Email</Title>
      <TextField value={data?.data?.email ?? ""} />
      <Title level={5}>Is Active</Title>
      <TextField value={data?.data?.isActive ? "Yes" : "No"} />
      <Title level={5}>Store Access</Title>
      <TextField
        value={data?.data?.stores
          ?.map((store: IStore) => store.name)
          .join(", ")}
      />
      <Title level={5}>Role</Title>
      <TextField value={data?.data?.role?.name ?? ""} />
      <Title level={5}>Team</Title>
      <TextField value={data?.data?.team?.name ?? ""} />
    </Show>
  );
};
