import { useCan } from "@refinedev/core";
import { Row, Col, Tabs } from "antd";

import { UserStat } from "components";
import { AppUsages } from "components/dashboard/appUsages";
import { PollUsages } from "components/dashboard/pollUsages";

export const DashboardPage: React.FC = () => {
  const { data } = useCan({
    resource: "dashboards",
    action: "list",
  });

  const tabs = [
    {
      key: "app",
      label: "App usage",
      children: <AppUsages />,
    },
    {
      key: "poll",
      label: "Poll usage",
      children: <PollUsages />,
    },
  ];

  if (!data?.can) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", fontSize: "34px" }}
      >
        Welcome to DFS MySA CMS
      </div>
    );
  }

  return (
    <Row gutter={[16, 16]} justify='center'>
      <Col md={24}>
        <UserStat />
      </Col>
      <Col md={18}>
        <Tabs defaultActiveKey='1' items={tabs} />
      </Col>
    </Row>
  );
};
