import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  TagField,
  DeleteButton,
} from "@refinedev/antd";
import { Table, Space } from "antd";

export const PollList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex={["teams"]}
          title="Stores"
          render={(value: any[]) => {
            const uniqueStoreNames = Array.from(new Set(value.map(team => team.store.name)));
            return (
              <>
                {uniqueStoreNames?.map((item, index) => (
                  <TagField value={item} key={index} />
                ))}
              </>
            );
          }}
        />
        <Table.Column
          dataIndex="teams"
          title="Team"
          render={(value: any[]) => (
            <>
              {value?.map((item) => (
                <TagField value={item?.name} key={item?.name} />
              ))}
            </>
          )}
        />
        <Table.Column
          dataIndex="themes"
          title="Theme"
          render={(value: any[]) => value.length}
        />
        <Table.Column
          dataIndex={["oneOffStartDay"]}
          title="Poll Period"
          render={(value: any, obj: any) =>
            obj.mode === "One-off" ? <>
              <DateField value={obj.oneOffStartDay} /> -
              <DateField value={obj.oneOffEndDay} />
            </> : <>{obj.startDayFromMonthEnd} days at month end</>
          }
        />
        <Table.Column dataIndex="mode" title="Mode" />
        <Table.Column dataIndex="status" title="Status" />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
