import { useMemo } from "react";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Card, Col, Row, Typography } from "antd";

import { IUserStat } from "interfaces";

export const UserStat: React.FC = () => {
  const t = useTranslate();

  const API_URL = useApiUrl();
  const url = `${API_URL}/dashboard`;
  const { data } = useCustom<{
    data: IUserStat;
  }>({ url, method: "get" });

  const { Text, Title } = Typography;

  const result = useMemo(() => {
    return data?.data.data;
  }, [data]);

  const userStat = (count: number | undefined, title: string) => {
    return (
      <Card
        bodyStyle={{
          padding: 50,
          textAlign: "center",
        }}
      >
        <Title>{(count ?? 0).toLocaleString()} </Title>
        <Text>{t(title)}</Text>
      </Card>
    );
  };

  return (
    <Row gutter={[16, 16]} justify='center'>
      <Col xl={6} lg={6} md={24} sm={24} xs={24}>
        {userStat(result?.sa, "Total SA Users ")}
      </Col>
      <Col xl={6} lg={6} md={24} sm={24} xs={24}>
        {userStat(result?.teamLead, "Total Team Lead Users ")}
      </Col>
      <Col xl={6} lg={6} md={24} sm={24} xs={24}>
        {userStat(result?.manager, "Total Manager Users ")}
      </Col>
    </Row>
  );
};
