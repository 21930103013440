import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select } from "antd";
import { IService } from "interfaces";
import { PermissionAction } from "enums";

export const PermissionEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();

  const permissionsData = queryResult?.data?.data;

  const { selectProps: serviceSelectProps } = useSelect<IService>({
    resource: "services",
    optionLabel: "name",
    optionValue: "id",
  });

  const permissionOptions = Object.keys(PermissionAction).map((key) => ({
    label: key,
    value: PermissionAction[key as keyof typeof PermissionAction],
  }));


  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical"
        onFinish={(values: any) => {
          const result = {
            ...values,
            service: values.serviceEdit,
          }
          onFinish(result).catch((error) => error)
        }}
      >
        <Form.Item
          label="Id"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Service"
          name='serviceEdit'
          rules={[
            {
              required: true,
            }
          ]}
          initialValue={[permissionsData?.service?.id]}
        >
          <Select
            {...serviceSelectProps}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label="Action"
          name={["action"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            placeholder='Select Service'
            options={permissionOptions}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
