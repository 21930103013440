import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show, NumberField, TextField, TagField } from "@refinedev/antd";
import { Typography } from "antd";
import { IPermission } from "interfaces";

const { Title } = Typography;

export const RoleShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;
  const permissionGroups = record?.permissions?.reduce(
    (groups: { [key: string]: string[] }, permission: IPermission) => {
      const serviceName = permission?.service?.name;
      const action = permission?.action;
      if (serviceName && action) {
        if (!groups[serviceName]) {
          groups[serviceName] = [];
        }
        groups[serviceName].push(action);
      }
      return groups;
    },
    {}
  );

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Permissions</Title>
      <>
        {permissionGroups &&
          Object.entries(permissionGroups).map(([serviceName, actions]) => (
            <div key={serviceName}>
              <h4>{serviceName}</h4>
              <TagField value={actions as React.ReactNode[]} />
            </div>
          ))}
      </>
    </Show>
  );
};
