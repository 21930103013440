import { useMemo, useState } from "react";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Card, Col, Dropdown, Menu, MenuProps, Row, Space, Typography } from "antd";
import _ from "lodash";
import { IAppUsage, IRegion } from "interfaces";
import { DownOutlined } from "@ant-design/icons";
import { Column, Bar, ColumnConfig, BarConfig } from "@ant-design/charts";

export const AppUsages: React.FC = () => {
  const t = useTranslate();
  const [statInterval] = useState("daily");
  const [selectedKey, setSelectedKey] = useState('0');

  const API_URL = useApiUrl();
  const url = `${API_URL}/dashboard/app-usage/${selectedKey !== '0' ? selectedKey : ''}`;
  const { data } = useCustom<{
    data: IAppUsage;
  }>({ url, method: "get" });

  const { Text, Title } = Typography;

  const result = useMemo(() => {
    return data?.data.data;
  }, [data]);

  const userStat = (count: number | undefined, title: string) => {
    return (
      <Card
        bodyStyle={{
          padding: 50,
          textAlign: "center",
        }}
      >
        <Title>{(count ?? 0).toLocaleString()} </Title>
        <Text>{t(title)}</Text>
      </Card>
    );
  };

  const statIntervalItems: MenuProps["items"] = [
    {
      key: "1",
      label: <span>Daily</span>,
    },
    {
      key: "2",
      label: <span>Weekly</span>,
    },
  ];

  const configActiveUser: ColumnConfig = {
    data: result?.activeUserByDaily,
    xField: "day",
    yField: "numbers",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Day of month",
      },
      numbers: {
        alias: "active users",
      },
    },
  };

  const viewsActiveUser: ColumnConfig = {
    data: result?.viewsByDaily,
    xField: "day",
    yField: "numbers",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Day of month",
      },
      numbers: {
        alias: "views",
      },
    },
  };

  const configStore: BarConfig = {
    data: result?.activeUserByStore,
    xField: "percentage",
    yField: "store",
    barWidthRatio: 0.1,
    meta: {
      store: {
        alias: "store",
      },
      percentage: {
        alias: "percentage",
      },
    },
  };

  const regionUrl = `${API_URL}/regions`;
  const { data: regionData } = useCustom<{
    data: IRegion[];
  }>({ url: regionUrl, method: "get" });

  const regionResult = useMemo(() => {
    return regionData?.data.data;
  }, [regionData]);

  const regionList = regionResult?.map((item: any) => {
    return {
      key: item.id.toString(),
      label: <span>{item.name}</span>,
    };
  }) as { key: string; label: JSX.Element }[];

  const regions: MenuProps["items"] = regionList ? [
    {
      key: 0,
      label: <span>All Region</span>,
    },
    ...regionList,
  ] : [];

  const selectedRegion = regionResult?.find((region) => region.id.toString() === selectedKey);
  const label = selectedRegion ? <span>{selectedRegion.name}</span> : 'All Regions';
  const handleMenuSelect = ({ key }: { key: string }) => {
    setSelectedKey(key);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title level={3}>{t("App Usage")}</Title>
        <Dropdown menu={{ items: statIntervalItems }}>
          <span
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Space>
              {_.upperFirst(statInterval)}
              <DownOutlined />
            </Space>
          </span>
        </Dropdown>
      </div>
      <div style={{ marginTop: "16px", marginBottom: "26px" }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            {userStat(result?.appUsageCount?.users.number, "Daily Active Users")}
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            {userStat(result?.appUsageCount?.views.number, "Daily Views")}
          </Col>
        </Row>
        {result?.activeUserByDaily &&
          <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card title='Active User per day' extra={<span>Past 30 days</span>}>
                <div style={{ padding: "20px" }}>
                  <Column {...configActiveUser} />
                </div>
              </Card>
            </Col>
          </Row>
        }
        {result?.viewsByDaily &&
          <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card title='Views per day' extra={<span>Past 30 days</span>}>
                <div style={{ padding: "20px" }}>
                  <Column {...viewsActiveUser} />
                </div>
              </Card>
            </Col>
          </Row>
        }
        {result?.activeUserByStore && regions &&
          <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card
                title={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>Daily Active User % by Store </span>
                  </div>
                }
                extra={
                  <Dropdown
                    overlay={
                      <Menu onClick={handleMenuSelect}>
                        {regions.map((region) => (
                          <Menu.Item key={region?.key}>{
                            region?.key ?
                              regionResult?.find((a) => a.id.toString() === region?.key)?.name
                              : 'All Region'
                          }</Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Space>
                        {label}
                        <DownOutlined />
                      </Space>
                    </span>
                  </Dropdown>
                }
              >
                <div style={{ padding: "20px" }}>
                  <Bar {...configStore} />
                </div>
              </Card>
            </Col>
          </Row>
        }
      </div>
    </>
  );
};
