import {
  useApiUrl,
  useCustom,
  useGetIdentity,
  useGetLocale,
  useInfiniteList,
  useNavigation,
  useSetLocale,
} from "@refinedev/core";
import {
  Layout as AntdLayout,
  Avatar,
  Badge,
  Button,
  Popover,
  Space,
  Tabs,
  TabsProps,
  Typography,
  theme,
  Spin,
  MenuProps,
  Switch,
  Dropdown,
} from "antd";
import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { ColorModeContext } from "../../contexts/color-mode";
import { BellFilled, DownOutlined, LoadingOutlined } from "@ant-design/icons";
import "./index.css";
import { INotification } from "interfaces";
import { useTranslation } from "react-i18next";

const { Text } = Typography;
const { useToken } = theme;

type IUser = {
  id: number;
  name: string;
  avatar: string;
};

export const Header: React.FC = () => {
  const { token } = useToken();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("unread");

  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity<IUser>();

  const { show } = useNavigation();
  const { mode, setMode } = useContext(ColorModeContext);

  const currentLocale = locale();

  const menuItems: MenuProps["items"] = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      onClick: () => changeLanguage(lang),
      icon: (
        <span style={{ marginRight: 8 }}>
          <Avatar size={16} src={`/images/flags/${lang}.svg`} />
        </span>
      ),
      label: lang === "en" ? "English" : "German",
    }));

  const apiUrl = useApiUrl();
  const { data: dataUnread } = useCustom({
    url: `${apiUrl}/notification/unread`,
    method: "get",
    config: {},
  });

  const {
    data,
    isRefetching,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteList<INotification>({
    resource: "notification",
    pagination: {
      pageSize: 5,
    },
    filters: [
      {
        field: "type",
        operator: "eq",
        value: type,
      },
    ],
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [refetch]);

  const getNotificationList = () => {
    return (
      <div
        style={{ height: "420px", overflowY: "scroll" }}
        onScroll={handleScroll}
      >
        {data?.pages?.map((page) =>
          page.data.map((item, index) => {
            return (
              <div key={`${item.id}-${index}`} className='notification-item'>
                <div className='notification-left'>
                  <div className='notification-unread'></div>
                </div>
                <div className='notification-right'>
                  <div className='notification-header'>
                    <span className='notification-title'>{item.title}</span>
                    <span className='notification-time'>
                      {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm")}
                    </span>
                  </div>
                  <div>
                    <p className='notification-body'>{item.content}</p>
                  </div>
                  <Button
                    shape='round'
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={() => {
                      show("polls", item.extra?.poll_id);
                      setOpen(false);
                    }}
                  >
                    View poll
                  </Button>
                </div>
              </div>
            );
          })
        )}
        {hasNextPage && isFetchingNextPage && (
          <div className='notification-loading'>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        )}
      </div>
    );
  };

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (hasNextPage && bottom) {
      fetchNextPage();
    }
  };

  const noticeTabItems: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <>
          Unread <Badge count={dataUnread?.data?.data ?? 0} />
        </>
      ),
      children: (
        <>
          {isRefetching ? (
            <div
              style={{
                height: "420px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : (
            getNotificationList()
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "All",
      children: (
        <>
          {isRefetching ? (
            <div
              style={{
                height: "420px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : (
            getNotificationList()
          )}
        </>
      ),
    },
  ];

  return (
    <AntdLayout.Header
      style={{
        backgroundColor: token.colorBgElevated,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
      }}
    >
      <Space>
        <Dropdown
          menu={{
            items: menuItems,
            selectedKeys: currentLocale ? [currentLocale] : [],
          }}
        >
          <Button type="text">
            <Space>
              <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
              {currentLocale === "en" ? "English" : "German"}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
       

        <Popover
          content={
            <div
              style={{
                width: "400px",
                height: "500px",
                padding: "10px 16px",
                backgroundColor: "#f8f8f8",
              }}
            >
              <Tabs
                items={noticeTabItems}
                onChange={(v: any) => {
                  setType(v === 2 ? "all" : "unread");
                }}
              />
            </div>
          }
          placement='bottomLeft'
          trigger='click'
          open={open}
          onOpenChange={(newOpen: boolean) => {
            setOpen(newOpen);
          }}
        >
          <Badge count={dataUnread?.data?.data ?? 0}>
            <Button icon={<BellFilled />} />
          </Badge>
        </Popover>
        <Switch
          checkedChildren='🔆'
          unCheckedChildren='🌛'
          onChange={() => setMode(mode === "light" ? "dark" : "light")}
          defaultChecked={mode === "light"}
        />

        <Space style={{ marginLeft: "8px" }} size='middle'>
          {user?.name && <Text strong>{user.name}</Text>}
          {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
        </Space>
      </Space>
    </AntdLayout.Header>
  );
};
