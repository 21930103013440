import { HttpError } from "@refinedev/core";
import { TOKEN_KEY } from "utils/authProvider";
import axios, { AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { API_URL } from "utils/apiConfig";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers) {
    if (!config.headers.Authorization) {
      const user = localStorage.getItem(TOKEN_KEY);
      if (user) {
        const { accessToken } = JSON.parse(user);
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    let processedURL = new URL(config.url as string);
    
    if(processedURL.searchParams.has('s') && config.method == 'get') {
      processedURL.searchParams.set('s',JSON.stringify({'encodedQueryString': `${btoa(processedURL.searchParams.get('s') || '')}`}));

      config.url = processedURL.toString();
    }
  }
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const message = error.response?.data?.message
      ? error.response?.data?.message
      : error.response?.data?.error;

    const customError: HttpError = {
      ...error,
      message: Array.isArray(message) ? message.join("\n") : message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

const refreshAuthLogic = (failedRequest: any) => {
  console.log("触发refresh中间件");
  console.log(TOKEN_KEY);
  // 取出原值
  const authInfoStr: any = localStorage.getItem(TOKEN_KEY);
  const authInfo = JSON.parse(authInfoStr);
  console.log(authInfo);

  if (!authInfo?.refreshToken) {
    console.error("获取authInfo失败");
    console.log(authInfo);
    return Promise.reject();
  }

  return axiosInstance
    .post(
      `${API_URL}/auth/refresh`,
      {},
      {
        headers: { Authorization: `Bearer ${authInfo.refreshToken}` },
      }
    )
    .then((tokenRefreshResponse) => {
      console.log("refresh res:");
      console.log(tokenRefreshResponse?.data?.data);
      authInfo.accessToken = tokenRefreshResponse?.data?.data?.accessToken;
      authInfo.refreshToken = tokenRefreshResponse?.data?.data?.refreshToken;
      console.log("new authInfo:");
      console.log(authInfo);
      localStorage.setItem(TOKEN_KEY, JSON.stringify(authInfo));

      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + authInfo.accessToken;

      return Promise.resolve();
    });
};
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

export default axiosInstance;
