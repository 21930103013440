import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useCustomMutation,
  useTranslate,
  useUpdate,
  useOne,
} from "@refinedev/core";
import {
  useEditableTable,
  List,
  useSelect,
  SaveButton,
  useTable,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Menu,
  Form,
  Select,
  Button,
  Dropdown,
  Tabs,
  Upload,
  message,
  Modal,
  Spin,
} from "antd";
import {
  FormOutlined,
  MoreOutlined,
  UploadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { ILanguage, IRegion } from "interfaces";
import type { TabsProps, UploadProps } from "antd";
import { v4 as uuid } from "uuid";

export const DefaultLanguage: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const {
    tableProps,
    formProps,
    isEditing,
    onFinish,
    saveButtonProps,
    cancelButtonProps,
    setId: setEditId,
  } = useEditableTable<IRegion>({
    resource: "regions",
    syncWithLocation: false,
  });

  const { selectProps: languageSelectProps } = useSelect<ILanguage>({
    resource: "languages",
    optionLabel: "name",
    optionValue: "id",
  });

  const moreMenu = (record: IRegion) => (
    <Menu
      mode='vertical'
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      <Menu.Item
        key='accept'
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <FormOutlined
            style={{
              color: "#52c41a",
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        onClick={() => {
          setEditId?.(record.id);
        }}
      >
        {t("buttons.edit")}
      </Menu.Item>
    </Menu>
  );

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Default language",
      children: (
        <Form
          {...formProps}
          onFinish={(values: any) => {
            const result = {
              language: { id: values.languageEdit },
            };

            onFinish(result)
              .then(() => {
                setEditId(undefined);
              })
              .catch((error) => error);
          }}
        >
          <Table
            {...tableProps}
            rowKey='id'
            onRow={(record) => ({
              // eslint-disable-next-line
              onClick: (event: any) => {
                if (event.target.nodeName === "TD") {
                  setEditId && setEditId(record.id);
                }
              },
            })}
          >
            <Table.Column dataIndex='name' title='Region' width={160} />
            <Table.Column
              dataIndex='language'
              title='Language'
              render={(value: any, record: IRegion) => {
                if (isEditing(record.id)) {
                  return (
                    <Form.Item
                      style={{
                        marginBottom: "0px",
                      }}
                      name='languageEdit'
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      initialValue={record?.language?.id}
                    >
                      <Select
                        {...languageSelectProps}
                        allowClear
                        placeholder={t("Select Language")}
                      />
                    </Form.Item>
                  );
                } else {
                  return <span>{record?.language?.name}</span>;
                }
              }}
            />
            <Table.Column
              title='ACTIONS'
              dataIndex='actions'
              render={(_text, record: IRegion) => {
                if (isEditing(record.id)) {
                  return (
                    <Space>
                      <SaveButton {...saveButtonProps} size='small' />
                      <Button {...cancelButtonProps} size='small'>
                        {t("buttons.cancel")}
                      </Button>
                    </Space>
                  );
                }
                return (
                  <Dropdown overlay={moreMenu(record)} trigger={["click"]}>
                    <MoreOutlined
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontSize: 24,
                      }}
                    />
                  </Dropdown>
                );
              }}
            />
          </Table>
        </Form>
      ),
    },
    {
      key: "2",
      label: "Language package",
      children: <LanguagePackage />,
    },
  ];

  return (
    <List>
      <Tabs items={items} />
    </List>
  );
};

const LanguagePackage: React.FC<IResourceComponentsProps> = () => {
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState<ILanguage>();
  const [openPackage, setOpenPackage] = useState(false);

  const { tableProps } = useTable<ILanguage>({
    resource: "languages",
  });

  const apiUrl = useApiUrl();

  const { data: dataSignParams } = useCustom({
    url: `${apiUrl}/upload/getSignParams`,
    method: "get",
  });

  const { data, isLoading } = useOne({
    resource: "languages/package",
    id: language?.id ?? 0,
    queryOptions: {
      enabled: !!language?.id,
    },
  });

  console.log(data);

  const { mutate: customMutate } = useCustomMutation();

  const { mutate } = useUpdate();

  const props: UploadProps = {
    name: "file",
    accept: "application/json",
    maxCount: 1,
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload(file) {
      if (dataSignParams) {
        const formData = new FormData();
        const key = `${uuid()}.json`;
        formData.append("key", key);
        formData.append("policy", dataSignParams?.data?.data?.policy);
        formData.append("OSSAccessKeyId", dataSignParams?.data?.data?.accessId);
        formData.append("success_action_status", "200");
        formData.append("signature", dataSignParams?.data?.data?.signature);
        formData.append("file", file);
        customMutate(
          {
            url: dataSignParams?.data?.data?.host,
            method: "post",
            values: formData,
          },
          {
            onError: (error, variables, context) => {
              console.error("Upload failed");
              console.error(error);
            },
            onSuccess: (data, variables, context) => {
              setLanguage((prevState) => {
                if (prevState) {
                  return {
                    ...prevState,
                    package: key,
                  };
                }
              });
            },
          }
        );
      }
      return false;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <Modal
        centered
        title={`${language?.name} package`}
        open={openPackage}
        onCancel={() => {
          setOpenPackage(false);
          setLanguage(undefined);
        }}
        cancelButtonProps={{ disabled: true }}
        onOk={() => {
          setOpenPackage(false);
          setLanguage(undefined);
        }}
      >
        {isLoading ? <Spin /> : <>{data?.data}</>}
      </Modal>
      <Modal
        centered
        title={`Upload ${language?.name} package`}
        open={open}
        onOk={() => {
          if (language?.package) {
            mutate({
              resource: "languages",
              id: language?.id || 0,
              values: {
                package: language.package,
              },
            });
            setOpen(false);
            setLanguage(undefined);
          }
        }}
        onCancel={() => {
          setOpen(false);
          setLanguage(undefined);
        }}
      >
        <Form>
          <Form.Item
            label='Upload package'
            help='Please upload a text file with a .json extension'
          >
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='name' title='Language' width={160} />
        <Table.Column dataIndex='slug' title='Slug' width={160} />
        <Table.Column
          dataIndex='package'
          title='Package'
          render={(value: any, record: ILanguage) => {
            return (
              <span>
                <div>
                  {value}
                  <Button
                    style={{ marginLeft: "8px" }}
                    icon={<EyeOutlined />}
                    onClick={() => {
                      setOpenPackage(true);
                      setLanguage(record);
                    }}
                  />
                  <Button
                    style={{ marginLeft: "8px" }}
                    icon={<UploadOutlined />}
                    onClick={() => {
                      setOpen(true);
                      setLanguage(record);
                    }}
                  ></Button>
                </div>
              </span>
            );
          }}
        />
      </Table>
    </>
  );
};
