import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Divider,
  Descriptions,
  Space,
  Button,
  FormListFieldData,
} from "antd";
import { ITeam } from "interfaces";
import Paragraph from "antd/es/typography/Paragraph";
import moment from "moment";
import dayjs from "dayjs";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { SortableList } from "components";
import Badge from "components/poll/Badge";
import _ from "lodash";

export const PollEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form, onFinish } = useForm();

  const PollsData = queryResult?.data?.data;

  const { selectProps: teamsSelectProps } = useSelect<ITeam>({
    resource: "teams",
    optionLabel: "name",
    optionValue: "id",
  });
  const { RangePicker } = DatePicker;
  const startDayFromMonthEndValue = Form.useWatch("startDayFromMonthEnd", form);
  const endPollRepeatDateValue = Form.useWatch("endPollRepeatDate", form);
  const endOfMonth = () => {
    const endOfMonth = moment().endOf("month");
    if (endPollRepeatDateValue && endPollRepeatDateValue < endOfMonth) {
      return endPollRepeatDateValue;
    }

    return endOfMonth;
  };

  const nextPeriodStartDay = moment()
    .endOf("month")
    .subtract(startDayFromMonthEndValue, "days");

  if (formProps.initialValues?.themes) {
    const sorted = _.sortBy(formProps.initialValues.themes, "order");
    formProps.initialValues.themes = sorted;
  }

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout='vertical'
        onFinish={(values: any) => {
          values.themes?.forEach((theme: any, index: number) => {
            theme.order = index + 1;
            return theme;
          });
          const result = {
            teams: values.teamsEdit.map((id: number) => ({ id })),
            mode: values.mode,
            oneOffStartDay: values.pollPeriod?.[0],
            oneOffEndDay: values.pollPeriod?.[1],
            startDayFromMonthEnd: values.startDayFromMonthEnd,
            endPollRepeatDate: values.endPollRepeatDate,
            themes: values.themes,
          };
          onFinish(result).catch((error) => error);
        }}
      >
        <Descriptions title='Participant'>
          <Descriptions.Item>
            Who would you like to invite to the poll?
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <Form.Item
          label='Id'
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label='Selected team(s)'
          name='teamsEdit'
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={PollsData?.teams?.map((team: ITeam) => team.id)}
        >
          <Select {...teamsSelectProps} allowClear mode='multiple' />
        </Form.Item>

        <Divider />
        <Descriptions title='Theme'>
          <Descriptions.Item>
            What are your participants voting for?
          </Descriptions.Item>
        </Descriptions>
        <Divider />

        <Form.List name='themes'>
          {(fields: FormListFieldData[], { add, remove, move }) => {
            let items: any[] = fields;

            items.forEach((item) => {
              console.log(item);
              item.id = item.key.toString();
              return item;
            });

            return (
              <>
                <SortableList
                  items={items}
                  move={move}
                  renderItem={(item) => {
                    return (
                      <SortableList.Item id={item.id}>
                        <SortableList.DragHandle />
                        <Space
                          key={item.key}
                          style={{ display: "flex", alignItems: "center" }}
                          align='center'
                        >
                          <Form.Item
                            {...item}
                            name={[item.name, "title"]}
                            key={`${item.key}-theme`}
                            style={{
                              width: "60vh",
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter title",
                              },
                            ]}
                          >
                            <Input placeholder='Theme' />
                          </Form.Item>
                          <Form.Item
                            style={{
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                            {...item}
                            name={[item.name, "badge"]}
                            key={`${item.key}-badge`}
                            rules={[
                              {
                                required: true,
                                message: "Please select badge",
                              },
                            ]}
                          >
                            <Badge
                              value={form.getFieldValue([item.name, "badge"])}
                              onChange={(value) => {
                                form.setFields([
                                  { name: [item.name, "badge"], value },
                                ]);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            style={{
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <MinusCircleOutlined
                              onClick={() => remove(item.name)}
                            />
                          </Form.Item>
                        </Space>
                      </SortableList.Item>
                    );
                  }}
                />
                <Form.Item>
                  <Button
                    type='dashed'
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add item
                  </Button>
                </Form.Item>
              </>
            );

            // return (
            //   <>
            //     <DndContext
            //       onDragEnd={(events) => {
            //         const { active, over } = events;
            //         if (over) {
            //           if (active.id !== over?.id) {
            //             let activeIdx = 0;
            //             let overIdx = 0;
            //             for (const idx in items) {
            //               if (items[idx].id == active.id) {
            //                 activeIdx = parseInt(idx);
            //               } else if (items[idx].id == over?.id) {
            //                 overIdx = parseInt(idx);
            //               }
            //             }
            //             move(activeIdx, overIdx);
            //           }
            //         }
            //       }}
            //     >
            //       <SortableContext items={items}>
            //         {items.map((item) => {
            //           return (
            //             <SortableItem key={item.key} id={item.key}>
            //               <Space
            //                 key={item.key}
            //                 style={{ display: "flex", marginBottom: 8 }}
            //                 align='baseline'
            //               >
            //                 <Form.Item>
            //                   <div
            //                   // draggable={true}
            //                   >
            //                     <HolderOutlined />
            //                   </div>
            //                 </Form.Item>
            //                 <Form.Item
            //                   {...item}
            //                   name={[item.name, "title"]}
            //                   key={`${item.key}-theme`}
            //                   style={{ width: "60vh" }}
            //                   rules={[
            //                     {
            //                       required: true,
            //                       message: "Please enter title",
            //                     },
            //                   ]}
            //                 >
            //                   <Input placeholder='Theme' />
            //                 </Form.Item>
            //                 <Form.Item
            //                   {...item}
            //                   name={[item.name, "badge"]}
            //                   key={`${item.key}-badge`}
            //                   rules={[
            //                     {
            //                       required: true,
            //                       message: "Please enter badge",
            //                     },
            //                   ]}
            //                 >
            //                   <Input placeholder='Badge' />
            //                 </Form.Item>
            //                 <Form.Item>
            //                   <MinusCircleOutlined
            //                     onClick={() => remove(item.name)}
            //                   />
            //                 </Form.Item>
            //               </Space>
            //             </SortableItem>
            //           );
            //         })}
            //       </SortableContext>
            //     </DndContext>
            //     {/* {fields.map((field, index) => {
            //       return (
            //         <Space
            //           key={field.key}
            //           style={{ display: "flex", marginBottom: 8 }}
            //           align='baseline'
            //         >
            //           <Form.Item>
            //             <div>
            //               <HolderOutlined />
            //             </div>
            //           </Form.Item>
            //           <Form.Item
            //             {...field}
            //             name={[field.name, "title"]}
            //             key={`${field.key}-theme`}
            //             style={{ width: "60vh" }}
            //             rules={[
            //               {
            //                 required: true,
            //                 message: "Please enter title",
            //               },
            //             ]}
            //           >
            //             <Input placeholder='Theme' />
            //           </Form.Item>
            //           <Form.Item
            //             {...field}
            //             name={[field.name, "badge"]}
            //             key={`${field.key}-badge`}
            //             rules={[
            //               {
            //                 required: true,
            //                 message: "Please enter badge",
            //               },
            //             ]}
            //           >
            //             <Input placeholder='Badge' />
            //           </Form.Item>
            //           <Form.Item>
            //             <MinusCircleOutlined
            //               onClick={() => remove(field.name)}
            //             />
            //           </Form.Item>
            //         </Space>
            //       );
            //     })} */}
            //     <Form.Item>
            //       <Button
            //         type='dashed'
            //         onClick={() => add()}
            //         block
            //         icon={<PlusOutlined />}
            //       >
            //         Add item
            //       </Button>
            //     </Form.Item>
            //   </>
            // );
          }}
        </Form.List>
        <Divider />

        <Descriptions title='Frequency'>
          <Descriptions.Item>
            How often would you like this poll to happen?
          </Descriptions.Item>
        </Descriptions>

        <Form.Item
          label='Poll Mode'
          name='mode'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        {PollsData?.mode === "Month-end" ? (
          <>
            <Paragraph>
              System to automatically trigger poll regularly
            </Paragraph>
            <Divider />

            <Form.Item
              label='Start day(s) before month-end'
              name='startDayFromMonthEnd'
              dependencies={["mode"]}
              rules={[
                ({ getFieldValue }) => ({
                  required: getFieldValue("mode") === "Month-end",
                }),
              ]}
            >
              <Input
                size='small'
                style={{ width: 50 }}
                maxLength={3}
                onChange={() => {
                  form.resetFields(["endPollRepeatDate"]);
                }}
              />
            </Form.Item>
            {startDayFromMonthEndValue ? (
              <Paragraph>
                Next poll period:{" "}
                {nextPeriodStartDay.format("YYYY-MM-DD 00:00:00")} to{" "}
                {endOfMonth().format("YYYY-MM-DD 23:59:59")}
              </Paragraph>
            ) : (
              ""
            )}
            <Form.Item
              label='Input date to end poll repeat'
              name='endPollRepeatDate'
              getValueProps={(value) => ({
                value: value ? dayjs(value) : undefined,
              })}
              rules={[
                ({ getFieldValue }) => ({
                  required: getFieldValue("mode") === "Month-end",
                }),
              ]}
            >
              <DatePicker
                disabledDate={(d) =>
                  !d || d.isBefore(nextPeriodStartDay.format("YYYY-MM-DD"))
                }
              />
            </Form.Item>
          </>
        ) : null}
        {PollsData?.mode === "One-off" ? (
          <>
            <Paragraph>
              Poll will only be started once during defined time period
            </Paragraph>
            <Divider />
            <Form.Item
              label='Poll period'
              name='pollPeriod'
              rules={[
                ({ getFieldValue }) => ({
                  required: getFieldValue("mode") === "One-off",
                }),
              ]}
              initialValue={[
                dayjs(PollsData?.oneOffStartDay),
                dayjs(PollsData?.oneOffEndDay),
              ]}
            >
              <RangePicker style={{ width: "100%" }} />
            </Form.Item>
          </>
        ) : null}
      </Form>
    </Edit>
  );
};
