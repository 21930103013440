import { AuthBindings } from "@refinedev/core";
import getUserByEmail from "utils/getUserByEmail";
import oktaAuth from "utils/oktaAuth";
import { IUser } from "interfaces";

export const TOKEN_KEY = "dfs-mysa-auth";

export const authProvider: AuthBindings = {
  login: async ({ username, email, password }) => {
    if ((username || email) && password) {
      const user = await getUserByEmail(username, password);
      localStorage.setItem(TOKEN_KEY, JSON.stringify(user));
      return {
        success: true,
        redirectTo: "/",
      };
    }

    return {
      success: false,
      error: {
        name: "LoginError",
        message: "Invalid email or password",
      },
    };
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    try {
      await oktaAuth.signOut();
    } catch (err) {
      console.log("okta sign out error", err);
    }
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const user = localStorage.getItem(TOKEN_KEY);
    if (user) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => {
    const user = localStorage.getItem(TOKEN_KEY);
    if (user) {
      const userObj = JSON.parse(user) as IUser;
      return userObj?.permissions;
    }

    return null;
  },
  getIdentity: async () => {
    const obj = localStorage.getItem(TOKEN_KEY);
    if (obj) {
      return JSON.parse(obj);
    }
    return null;
  },
  onError: async (error) => {
    if (error.statusCode === 403) {
      return {
        logout: true,
        redirectTo: "/login",
        error,
      };
    }
    return {};
  },
};
