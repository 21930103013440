import React, { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  CrudFilters,
  useTranslate,
  useCustomMutation,
  useApiUrl,
} from "@refinedev/core";
import { List, useSelect, useTable } from "@refinedev/antd";
import { Table, Tabs, Card, Form, Row, Col, Input, Select, Button } from "antd";
import type { TabsProps, FormProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { IRegion, IStore } from "interfaces";
import { TIMEZONES } from "enums/timzones.constant";

export const RegionList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const [isStoreEditing, setIsStoreEditing] = useState(false);
  const [isRegionEditing, setIsRegionEditing] = useState(false);

  const [deleteRegions, setDeleteRegions] = useState<IRegion[]>([]);
  const [regionUpdateData, setRegionUpdateData] = useState<IRegion[]>([]);

  const apiUrl = useApiUrl();

  const [formStore] = Form.useForm();
  const [formRegion] = Form.useForm();

  const {
    tableProps: storeTableProps,
    searchFormProps: storeSearchFormProps,
    tableQueryResult: storeTableQueryResult,
  } = useTable<IStore>({
    resource: "stores",
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q } = params;

      filters.push({
        field: "name",
        operator: "contains",
        value: q,
      });

      return filters;
    },
    syncWithLocation: false,
  });

  const {
    tableProps: regionTableProps,
    searchFormProps: regionSearchFormProps,
    tableQueryResult,
  } = useTable<IRegion>({
    resource: "regions",
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q } = params;

      filters.push({
        field: "name",
        operator: "contains",
        value: q,
      });

      return filters;
    },
    syncWithLocation: false,
  });

  useEffect(() => {
    setRegionUpdateData(tableQueryResult?.data?.data || []);
  }, [tableQueryResult.data]);

  const { selectProps: regionsSelectProps } = useSelect<IRegion>({
    resource: "regions",
    optionLabel: "name",
    optionValue: "id",
  });

  const { mutate } = useCustomMutation();

  const updateRegionName = (index: number, newName: string) => {
    setRegionUpdateData((prevRegions) => {
      const updatedRegions = [...prevRegions];
      updatedRegions[index] = { ...updatedRegions[index], name: newName };
      return updatedRegions;
    });
  };

  const items: TabsProps["items"] = [
    {
      key: "store",
      label: "Store",
      children: (
        <div>
          <div
            style={{
              marginBottom: "24px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <StoreFilter formProps={storeSearchFormProps} />
            <div>
              <Button
                type='primary'
                onClick={() => {
                  setIsStoreEditing(!isStoreEditing);
                  isStoreEditing && formStore.submit();
                }}
              >
                {isStoreEditing ? "Save" : "Edit"}
              </Button>
            </div>
          </div>
          <Form
            form={formStore}
            onFinish={(values: any) => {
              mutate(
                {
                  url: `${apiUrl}/stores/batch`,
                  method: "patch",
                  values: { records: values },
                  errorNotification: (data, values) => {
                    return {
                      message: "Update stores failed",
                      description: "Batch update stores region failed",
                      type: "error",
                    };
                  },
                },
                {
                  onSuccess: () => {
                    storeTableQueryResult.refetch();
                  },
                }
              );
            }}
          >
            <Table {...storeTableProps}>
              <Table.Column width={100} dataIndex='name' title='Store' />
              <Table.Column
                width={100}
                dataIndex={["region", "name"]}
                title='Region'
                render={(value: any, record: any) => {
                  if (isStoreEditing) {
                    return (
                      <Form.Item
                        key={record.id}
                        name={[record.id, "region"]}
                        style={{ marginBottom: "0px" }}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        initialValue={record?.region?.id}
                      >
                        <Select
                          {...regionsSelectProps}
                          allowClear
                          placeholder={t("Select Region")}
                        />
                      </Form.Item>
                    );
                  } else {
                    return <span>{record.region?.name}</span>;
                  }
                }}
              />
              <Table.Column
                width={200}
                dataIndex={"timezone"}
                title='Timezone'
                render={(value: any, record: any) => {
                  if (isStoreEditing) {
                    return (
                      <Form.Item
                        key={record.id}
                        name={[record.id, "timezone"]}
                        style={{ marginBottom: "0px" }}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        initialValue={record?.timezone}
                      >
                        <Select
                          options={TIMEZONES}
                          allowClear
                          placeholder={t("Select Timezone")}
                          showSearch
                        />
                      </Form.Item>
                    );
                  } else {
                    return <span>{record.timezone}</span>;
                  }
                }}
              />
            </Table>
          </Form>
        </div>
      ),
    },
    {
      key: "region",
      label: "Region",
      children: (
        <div>
          <div
            style={{
              marginBottom: "24px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <RegionFilter formProps={regionSearchFormProps} />
            <div>
              {isRegionEditing && (
                <Button
                  style={{ marginRight: "24px" }}
                  onClick={() => {
                    const data = regionUpdateData;
                    setRegionUpdateData([{ id: 0, name: "" }, ...data]);
                  }}
                >
                  + Add Region
                </Button>
              )}
              <Button
                type='primary'
                onClick={() => {
                  setIsRegionEditing(!isRegionEditing);
                  isRegionEditing && formRegion.submit();
                }}
              >
                {isRegionEditing ? "Save" : "Edit"}
              </Button>
            </div>
          </div>
          {isRegionEditing ? (
            <Form
              form={formRegion}
              onFinish={(values: any) => {
                const result = {
                  add: regionUpdateData.filter((region) => {
                    return region.id === 0;
                  }),
                  update: regionUpdateData.filter((region) => {
                    return region.id !== 0;
                  }),
                  remove: deleteRegions,
                };
                mutate(
                  {
                    url: `${apiUrl}/regions/batch`,
                    method: "patch",
                    values: result,
                    errorNotification: (data, values) => {
                      return {
                        message: "Save region failed",
                        description: "Save region failed",
                        type: "error",
                      };
                    },
                  },
                  {
                    onSuccess: () => {
                      tableQueryResult.refetch();
                    },
                  }
                );
              }}
            >
              <Table dataSource={regionUpdateData}>
                <Table.Column
                  key='index'
                  width={300}
                  dataIndex={"name"}
                  title='Region'
                  render={(value: any, record: any, index: number) => {
                    return (
                      <Input
                        value={record?.name}
                        onChange={(e) => {
                          updateRegionName(index, e.target.value);
                        }}
                      />
                    );
                  }}
                />
                <Table.Column
                  dataIndex='actions'
                  title='Actions'
                  render={(value: any, record: any, index: number) => {
                    return (
                      <Button
                        danger
                        onClick={(v) => {
                          if (record.id > 0) {
                            setDeleteRegions([...deleteRegions, record]);
                          }
                          const data = [];
                          for (let i = 0; i < regionUpdateData.length; i++) {
                            if (i !== index) {
                              data.push(regionUpdateData[i]);
                            }
                          }
                          setRegionUpdateData(data);
                          console.log(index);
                        }}
                      >
                        Delete
                      </Button>
                    );
                  }}
                />
              </Table>
            </Form>
          ) : (
            <Table {...regionTableProps}>
              <Table.Column
                width={300}
                dataIndex={"name"}
                title='Region'
                render={(value: any, record: any) => {
                  return <span>{record.name}</span>;
                }}
              />
            </Table>
          )}
        </div>
      ),
    },
  ];

  return (
    <List headerButtons={[]}>
      <Card>
        <Tabs defaultActiveKey='store' items={items}></Tabs>
      </Card>
    </List>
  );
};

const StoreFilter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();
  return (
    <Form
      layout='vertical'
      {...props.formProps}
      onValuesChange={() => {
        props.formProps.form?.submit();
      }}
    >
      <Row gutter={[10, 0]} align='bottom'>
        <Col xs={24} xl={24} md={12}>
          <Form.Item name='q' noStyle>
            <Input
              style={{
                width: "400px",
              }}
              placeholder={t("Search store")}
              suffix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const RegionFilter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();
  return (
    <Form
      layout='vertical'
      {...props.formProps}
      onValuesChange={() => {
        props.formProps.form?.submit();
      }}
    >
      <Row gutter={[10, 0]} align='bottom'>
        <Col xs={24} xl={24} md={12}>
          <Form.Item name='q' noStyle>
            <Input
              style={{
                width: "400px",
              }}
              placeholder={t("Search region")}
              suffix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
