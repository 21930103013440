export enum PermissionAction {
  LIST = "list",
  CREATE = "create",
  SHOW = "show",
  EDIT = "edit",
  DELETE = "delete",
}

export enum Badges {
  AWARD = "award",
  CONVO_HEART = "convo-heart",
  DIAMOND = "diamond",
  EXCELLENT = "excellent",
  HEART = "heart",
  IMPROVE = "improve",
  MOUNTAIN = "mountain",
  ROCKET = "rocket",
  TEAM = "team",
}
